<template>
  <div>
    <r-button-action
      :title="title"
      is-arrow
      :color="fill"
      @click="isOpen = !isOpen"
      :value="isOpen"
      :icon="icon"
    />
    <transition name="fade">
      <div
        class="list"
        v-if="isOpen"
      >
        <div
          class="list_item mt-4"
          v-for="item of list"
          :key="item"
        >
          <p>{{ item }}</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ButtonOpenList',
  props: {
    title: {
      type: String,
      default: 'Открыть список'
    },
    icon: {
      type: String,
      default: ''
    },
    list: {
      type: Object,
      required: true,
      default: () => {}
    },
    fill: {
      type: String,
      default: 'rocky'
    }
  },
  data() {
    return {
      isOpen: false
    };
  }
};
</script>

<style scoped>
svg.active {
  transform: rotate(180deg);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
