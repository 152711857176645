var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"table",staticClass:"table"},[_c('scroll-bar',{ref:"scrollView",attrs:{"options":{ alwaysShowTracks: true }},on:{"scroll":_vm.tableScroll}},[(_vm.headings.length > 0)?_c('div',{ref:"tableHead",staticClass:"table_head",style:(`transform: translate3d(0px, ${_vm.tableScrollY}px, 0px)`)},[_c('div',{staticClass:"table_row mozzarella",style:(!!_vm.columnsGrid
            ? 'grid-template-columns:' + _vm.columnsGrid.join(' ')
            : 'grid-auto-flow:column;')},[(_vm.checkbox)?_c('div',{staticClass:"table_col"},[(_vm.settings)?_c('select-checkboxes',{attrs:{"items":_vm.$store.getters.getTableInstantSettingFields,"is-choicest":_vm.headings},on:{"change":_vm.changeSettings}}):_vm._e()],1):_vm._e(),_vm._l((_vm.headings),function(head,index){return _c('div',{key:'head' + index,staticClass:"table_col opacity-48 pointer flex align-items-center",class:{
            'justify-content-center': _vm.centeredCols && head.field !== 'name'
          },on:{"click":function($event){return _vm.sortingClick(head.field)}}},[_vm._v(" "+_vm._s(head.title)+" "),_c('svg',{attrs:{"width":"16","height":"16","viewBox":"0 0 16 16","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M8.37829 11.835C8.16934 12.055 7.83058 12.055 7.62163 11.835L5.15665 9.96155C4.94771 9.74158 4.94771 9.38494 5.15665 9.16498C5.3656 8.94501 5.70436 8.94501 5.91331 9.16498L7.99996 10.6402L10.0866 9.16497C10.2956 8.94501 10.6343 8.94501 10.8433 9.16497C11.0522 9.38494 11.0522 9.74158 10.8433 9.96154L8.37829 11.835Z","fill":_vm.currentSortDir === 'desc' && _vm.currentSort === head.field ? '#3D75E4': '#C0D6F6'}}),_c('path',{attrs:{"fill-rule":"evenodd","clip-rule":"evenodd","d":"M7.6217 4.16497C7.83064 3.94501 8.16939 3.94501 8.37832 4.16497L10.8433 6.03846C11.0522 6.25842 11.0522 6.61506 10.8433 6.83503C10.6344 7.05499 10.2956 7.05499 10.0867 6.83503L8.00001 5.35983L5.91332 6.83503C5.70439 7.05499 5.36564 7.05499 5.1567 6.83503C4.94777 6.61506 4.94777 6.25842 5.1567 6.03846L7.6217 4.16497Z","fill":_vm.currentSortDir === 'asc' && _vm.currentSort === head.field ? '#3D75E4': '#C0D6F6'}})])])})],2)]):_vm._e(),(_vm.filteredList.length)?_c('div',{staticClass:"table_body",style:(`max-height: ${_vm.bodyMaxHeight}px`)},_vm._l((_vm.filteredList),function(row){return _c('router-link',{key:row.id + 'table_row',staticClass:"table_row",style:(!!_vm.columnsGrid
            ? 'grid-template-columns:' + _vm.columnsGrid.join(' ')
            : 'grid-auto-flow:column;'),attrs:{"to":_vm.rowLink + row.id + _vm.query}},[(_vm.checkbox)?_c('div',{staticClass:"table_col",on:{"click":function($event){$event.stopPropagation();}}},[_c('r-checkbox',{attrs:{"val":row.id,"value":_vm.selected},on:{"input":function($event){return _vm.selectedItem($event, row.id)}}})],1):_vm._e(),_vm._l((row.columns),function(col,colIndex){return _c('div',{key:row.id + 'table_col' + colIndex,staticClass:"table_col flex align-items-center",class:{
            'justify-content-center': _vm.centeredCols && colIndex !== 'name'
          }},[(colIndex === 'status')?_c('span',{staticClass:"bulb",class:{
              'fargo' : !col,
              'matrix' : col,
            }}):(_vm.showErrors)?_c('span',{staticClass:"overflow",class:{
              'color-fargo': row.errors[colIndex],
              'roquefort': colIndex === 'name',
              'feta': colIndex !== 'name',

            },attrs:{"title":!!col ? !!row.errors[colIndex] ? row.errors[colIndex] : col.title || col : null},domProps:{"innerHTML":_vm._s((!!col ? col.title || col : '—') + _vm.getSubtitle(col))}}):_c('span',{staticClass:"overflow",class:{
              'color-fargo': row.errors[colIndex],
              'roquefort': colIndex === 'name',
              'feta': colIndex !== 'name',

            },attrs:{"title":!!col ? col.title || col : null},domProps:{"innerHTML":_vm._s((!!col ? col.title || col : '—') + _vm.getSubtitle(col))}})])}),(_vm.deleteButton || _vm.copyButton || _vm.archiveButton)?_c('div',{staticClass:"table_col"},[_c('additional-menu',[_c('ul',{staticClass:"card__menu",staticStyle:{"display":"grid","grid-gap":"24px"}},[(_vm.copyButton)?_c('li',{staticClass:"sulguni flex pointer opacity-72 align-items-center",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.copyItem(row.id)}}},[_c('r-icon',{staticClass:"mr-3",attrs:{"size":"16","fill":"rocky","icon":"copy"}}),_vm._v(" Дублировать ")],1):_vm._e(),(row.active && _vm.archiveButton)?_c('li',{staticClass:"sulguni flex pointer opacity-72 align-items-center",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onArchive(row.id)}}},[_c('r-icon',{staticClass:"mr-3",attrs:{"size":"16","fill":"rocky","icon":"archive"}}),_vm._v(" Архивировать ")],1):_vm._e(),(_vm.deleteButton)?_c('li',{staticClass:"sulguni flex pointer opacity-72 align-items-center",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onDelete(row.id)}}},[_c('r-icon',{staticClass:"mr-3",attrs:{"size":"16","icon":"delete","fill":"fargo"}}),_vm._v(" Удалить ")],1):_vm._e()])])],1):_vm._e()],2)}),1):_c('not-found')],1),(_vm.selected.length)?_c('div',{staticClass:"object_manage_bar"},[_c('r-button-action',{staticClass:"color-rocky",attrs:{"title":"Скачать отчёт","icon":"file-download"},nativeOn:{"click":function($event){return _vm.openDownloadReportModal.apply(null, arguments)}}}),_c('r-button-action',{staticClass:"color-fargo",attrs:{"title":"Удалить","icon":"file-download","transparent":""},nativeOn:{"click":function($event){return _vm.onDelete(_vm.selected[0])}}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }