import { render, staticRenderFns } from "./TabCalendar.vue?vue&type=template&id=1bd200ae&scoped=true"
import script from "./TabCalendar.vue?vue&type=script&lang=js"
export * from "./TabCalendar.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bd200ae",
  null
  
)

export default component.exports