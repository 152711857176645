import Api from '@/api/Api';
import { formattedDate, getErrors, getNumberOfDays } from '../../helpers/utils';

const api = new Api();
export default {
  state: () => ({
    allObjects: [],
    isLoading: true,
    heatSeasonHiddenColumns: [],
    tableInstantHeadings: [
      {
        title: 'Наименование',
        field: 'name',
        colSize: '160px'
      },
      {
        title: 'Статус',
        field: 'status',
        colSize: '45px'
      },
      {
        title: 'tфакт, °C',
        field: 'tfact',
        colSize: '100px'
      },
      {
        title: 'tцел, °C',
        field: 'tcel',
        colSize: '80px'
      },
      {
        title: 't1, °C',
        field: 't_counter_data.t1_data',
        colSize: '80px'
      },
      {
        title: 't2, °C',
        field: 't_counter_data.t2_data',
        colSize: '80px'
      },
      {
        title: 't3, °C',
        field: 't_counter_data.t3_data',
        colSize: '80px'
      },
      {
        title: 'dt, °C',
        field: 't_counter_data.dt_data',
        colSize: '80px'
      },
      {
        title: 'P1, Мпа',
        field: 't_counter_data.p1_data',
        colSize: '80px'
      },
      {
        title: 'P2, Мпа',
        field: 't_counter_data.p2_data',
        colSize: '80px'
      },
      {
        title: 'P3, Мпа',
        field: 't_counter_data.p3_data',
        colSize: '80px'
      },
      {
        title: 'М1, т',
        field: 't_counter_data.m1_data',
        colSize: '80px'
      },
      {
        title: 'М2, т',
        field: 't_counter_data.m2_data',
        colSize: '80px'
      },
      {
        title: 'М3, т',
        field: 't_counter_data.m3_data',
        colSize: '80px'
      },
      {
        title: 'V1, м3',
        field: 't_counter_data.v1_data',
        colSize: '80px'
      },
      {
        title: 'V2, м3',
        field: 't_counter_data.v2_data',
        colSize: '80px'
      },
      {
        title: 'V3, м3',
        field: 't_counter_data.v3_data',
        colSize: '80px'
      },
      {
        title: 'tx, C',
        field: 'tx',
        colSize: '80px'
      },
      {
        title: 'Px, кгс/см2',
        field: 'Px',
        colSize: '110px'
      },
      {
        title: 'Qтв, Гкал',
        field: 'q0_cur_fin',
        colSize: '110px'
      },
      {
        title: 'Посл. показания',
        field: 't_counter_data.created',
        colSize: '180px'
      }
    ],
    filteredTableInstantHeadings: [],
    reportData: [
      {
        id: 'heat',
        title: 'Потребление'//'График по умолчанию'
      },
      {
        id: 'heat_t123',
        title: 'Температура воды (t1...t3) °C'
      },
      {
        id: 'heat_dt',
        title: 'Разность температур воды (t1-t2) °C'
      },
      {
        id: 'heat_p123',
        title: 'Избыточное давление (P1...P3) МПа'
      },
      {
        id: 'heat_v123',
        title: 'Объём воды (V1...V3) м3'
      },
      {
        id: 'heat_dv',
        title: 'Объём воды, отобранный из системы на ГВС, м3'
      },
      {
        id: 'heat_m123',
        title: 'Масса воды (М1...М3) т'
      },
      {
        id: 'heat_qq',
        title: 'Тепловая энергия (QТВ и QГ) Гкал'
      },
      {
        id: 'heat_nwt',
        title: 'Время нормальной работы (ВНР), час'
      },
      {
        id: 'heat_wct',
        title: 'Время отсутствия счёта (ВОС), час'
      },
      {
        id: 'heat_v12_inacc',
        title: 'Погрешность для объёма воды (V1...V2) %'
      }
      /* ,
        {
          id: 'summ',
          value: 'Итоговая ведомость, час'
        } */
    ],
    queryPeriodList: [
      /*{
        id: 'auto',
        title: 'Автоматическая'
      },*/
      {
        id: 'h',
        title: 'Часовая'
      },
      {
        id: 'd',
        title: 'Суточная'
      }
    ],
    period: [
      {
        id: 'DAY',
        title: 'День'
      },
      {
        id: 'WEEK',
        title: 'Неделя'
      },
      {
        id: 'MONTH',
        title: 'Месяц'
      },
      {
        id: 'FROM',
        title: 'Выбрать период'
      }
    ],
    mainTabs: [
      {
        id: 'general',
        title: 'Общие сведения'
      },
      {
        id: 'instant',
        title: 'Мгновенный расход'
      },
      {
        id: 'gvs',
        title: 'ГВС'
      },
      {
        id: 'settings',
        title: 'Настройки'
      },
      {
        id: 'calendar',
        title: 'Календарь поверок'
      }
    ]
  }),
  mutations: {
    setAllObjects(state, res) {
      state.allObjects = res.all;
      state.heatSeasonHiddenColumns = res?.heatSeasonHiddenColumns || [];

      if (state.heatSeasonHiddenColumns.length > 0) { // фильтрация столбцов таблицы мгновенный расход
        state.filteredTableInstantHeadings = [];
        state.filteredTableInstantHeadings = state.tableInstantHeadings.filter(
          el => !state.heatSeasonHiddenColumns.includes(el.field)
        );
      } else {
        state.filteredTableInstantHeadings = state.tableInstantHeadings;
      }
      if (res?.t2red?.length || res?.t3red?.length) { // отображение маркера ошибок для табов
        state.mainTabs = state.mainTabs.map(el => {
          if (
            (el.id === 'instant' && res?.t2red?.length)
            || (el.id === 'gvs' && res?.t3red?.length)
          ) {
            return {
              ...el,
              bulb: true,
              bulbColor: 'fargo'
            };
          }
          return el;
        });
      }
    },
    setLoading(state, bool) {
      state.isLoading = bool;
    }
  },
  actions: {
    async getAllObjects(ctx) {
      ctx.commit('setLoading', true);
      const res = await api.getAllObjects();
      ctx.commit('setAllObjects', res?.error ? [] : res);
      ctx.commit('setLoading', false);
      return res;
    },
    async getObjectById(ctx, id) {
      const res = await api.getObjectById(id);
      return res;
    },
    async operateHeatSeasons(ctx, payload) {
      const res = await api.operateHeatSeasons(payload);
      return res;
    }
  },
  getters: {
    getObjectsForTableGeneral(state) {
      return state?.allObjects?.map(el => ({
        id: el.id,
        columns: {
          name: el.name,
          address: el?.address || '-',
          calibrationDate: el?.calibrationDate,
          calibrationEmail: el?.calibrationEmail
        },
        lat: el.lat,
        lng: el.lng,
        errors: {
          calibrationDate: getNumberOfDays(el.calibrationDate) < 0
        }
      }));
    },
    getObjectsForTableInstant(state) {
      return state?.allObjects?.map(el => ({
        id: el.id,
        columns: {
          ...state.filteredTableInstantHeadings.reduce((acc, item) => {
            const dateSettings = {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric'
            };
            const getElement = (lib, keys) => {
              const key = keys.shift();
              return keys.length
                ? lib[key] ? getElement(lib[key], keys) : null
                : lib[key] ? lib[key] : null;
            };
            //
            if (item.field === 't_counter_data.created') {
              acc[item.field] = formattedDate(el?.t_counter_data?.created, dateSettings) || null;
            } else if (item.field === 'status') {
              acc[item.field] = el?.calibrationExpired ? el?.calibrationExpired?.length < 1 : false;
            } else {
              acc[item.field] = getElement(el, item.field.split('.')) || null;
            }

            return acc;
          }, {})
        },
        errors: {
          't_counter_data.t3_data': el.postgresErrorT3 || null,
          't_counter_data.p1_data': el.postgresErrorP1 || null,
          't_counter_data.p2_data': el.postgresErrorP2 || null
        }
      }));
    },
    getTableInstantColumns(state) {
      return {
        headings: state.filteredTableInstantHeadings,
        columnsGrid: ['16px', ...state.filteredTableInstantHeadings.map(el => el.colSize)]
      };
    },
    getTableInstantSettingFields(state) {
      return state.tableInstantHeadings.filter(el => el.field !== 'name' && el.field !== 't_counter_data.created');
    },
    getObjectsForTableGVS(state) {
      return state?.allObjects?.map(el => ({
        id: el.id,
        columns: {
          name: el.name,
          t3_data: el?.t_counter_data?.t3_data,
          v3_data: el?.t_counter_data?.v3_data,
          qg_data: el?.t_counter_data?.qg_data
        },
        errors: {
          't_counter_data.t3_data': el.postgresErrorT3 || null
        }
      }));
    }
  }
};
