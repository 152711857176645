<template>
  <div>
    <admin-header
      title="Отопление"
      :back="false"
      class="mb-7"
    />
    <r-tabs
      class="mt-7 mb-6"
      :items="$store.state.object.mainTabs"
      v-model="activeTabId"
    />
    <tab-general v-if="activeTabId.id === 'general'" />
    <tab-instant v-if="activeTabId.id === 'instant'" />
    <tab-g-v-s v-if="activeTabId.id === 'gvs'" />
    <tab-settings v-if="activeTabId.id === 'settings'" />
    <tab-calendar v-if="activeTabId.id === 'calendar'" />
  </div>
</template>

<script>
import AdminHeader from '@/components/AdminHeader.vue';
import TabGeneral from '../components/TabGeneral';
import TabInstant from '../components/TabInstant';
import TabGVS from '../components/TabGVS';
import TabSettings from '../components/TabSettings';
import TabCalendar from '../components/TabCalendar';

export default {
  name: 'ObjectList',
  components: {
    AdminHeader,
    TabGeneral,
    TabInstant,
    TabGVS,
    TabSettings,
    TabCalendar
  },
  data() {
    return {
      activeTabId: {
        id: 'general',
        title: 'Общие сведения'
      }
    };
  },
  async activated() {
    this.activeTabId = this.$store.state.object.mainTabs.find(item => item.id === this.$route.query.tab) || {
      id: 'general',
      title: 'Общие сведения'
    };
  },
  async created() {
    if (this.$store.state.object.allObjects.length < 1) {
      await this.$store.dispatch('getAllObjects');
    }
  }
};
</script>
<style scoped lang="scss">
::v-deep .r-tabs__bulb{
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-left: 4px;
  box-shadow: 0px 2px 8px rgba(238, 88, 126, 0.64) !important;
}
</style>
