<template>
  <div class="objects">
    <div class="flex mb-6 align-items-center">
      <r-date-picker
        class="flex-1"
        :disabled="false"
        :inline="false"
        :click-close="true"
        :period="true"
        label="Период"
        v-model="filterPeriod"
        @input="selectPeriod"
      />
    </div>
    <loader v-if="isLoading" />
    <template v-else>
      <drop-down-upload-button
        :items="['PDF', 'XLS']"
        class="mt-6"
        @selected="selectedDownloadFile"
      >
        <template>
          <r-button
            transparent
            class="color-rocky"
          >
            <r-icon
              size="16"
              class="mr-2"
              icon="file-download"
              fill="rocky"
            />
            Скачать отчёт
          </r-button>
        </template>
      </drop-down-upload-button>
      <div class="flex mt-2 mb-2">
        <div class="ml-auto parmigiano opacity-48">
          Всего {{ countObjects }}
        </div>
      </div>
      <scroll-table
        :list="list"
        :headings="headings"
        :centered="true"
        :show-errors="false"
        :checkbox="false"
        start-sorted-column="name"
        :columns-grid="columnsGrid"
        body-max-height="500"
      />
    </template>
  </div>
</template>

<script>

import moment from 'moment';
import NotFound from './NotFound';
import ScrollTable from './Table';
import { formattedDate, getNumberOfDays, wordMatch } from '../helpers/utils';
import Loader from './Loader';
import Api from '../api/Api';
import DropDownUploadButton from './DropDownUploadButton';

export default {
  name: 'ObjectTabHours',
  components: {
    Loader,
    NotFound,
    ScrollTable,
    DropDownUploadButton
  },
  data() {
    return {
      filterPeriod: [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
      headings: [
        {
          title: 'Дата и время',
          field: 'datef',
          colSize: '200px'
        },
        {
          title: 't1, °C',
          field: 't1_data',
          colSize: '60px'
        },
        {
          title: 't2, °C',
          field: 't2_data',
          colSize: '60px'
        },
        {
          title: 't3, °C',
          field: 't3_data',
          colSize: '60px'
        },
        {
          title: 'dt, °C',
          field: 'dt_data',
          colSize: '60px'
        },
        {
          title: 'P1, Мпа',
          field: 'p1_data',
          colSize: '80px'
        },
        {
          title: 'P2, Мпа',
          field: 'p2_data',
          colSize: '80px'
        },
        {
          title: 'P3, Мпа',
          field: 'p3_data',
          colSize: '80px'
        },
        {
          title: 'V1,\xa0м3',
          field: 'v1_data',
          colSize: '60px'
        },
        {
          title: 'V2,\xa0м3',
          field: 'v2_data',
          colSize: '60px'
        },
        {
          title: 'V3,\xa0м3',
          field: 'v3_data',
          colSize: '60px'
        },
        {
          title: 'М1, т',
          field: 'm1_data',
          colSize: '60px'
        },
        {
          title: 'М2, т',
          field: 'm2_data',
          colSize: '60px'
        },
        {
          title: 'М3, т',
          field: 'm3_data',
          colSize: '60px'
        },

        {
          title: 'dM, т',
          field: 'dm_data',
          colSize: '60px'
        },
        {
          title: 'tx, С',
          field: 'tx',
          colSize: '60px'
        },
        {
          title: 'Px, кгс/см²',
          field: 'Px',
          colSize: '110px'
        },
        {
          title: 'Qтв',
          field: 'q0_data',
          colSize: '60px'
        },
        {
          title: 'Qг, Гкал',
          field: 'qg_data',
          colSize: '80px'
        },
        {
          title: 'ВНР, ч',
          field: 'nwt_data',
          colSize: '80px'
        },
        {
          title: 'ВОС, ч',
          field: 'wct_data',
          colSize: '80px'
        },
        {
          title: 'Qтв(корр)',
          field: 'q0_data_corr',
          colSize: '80px'
        },
        {
          title: 'Примечание',
          field: 'error',
          colSize: '80px'
        }
      ],
      list: [],
      columnsGrid: [],
      isLoading: false
    };
  },
  computed: {
    countObjects() {
      return this.list.length;
    }
  },
  mounted() {
    this.getData();
  },
  methods: {
    selectedDownloadFile(file) {
      this.getData(file);
    },
    selectPeriod() {
      this.getData();
    },
    async getData(file = null) {
      const payload = {
        editMode: false,
        layer61OptionSub_1: false,
        layer61OptionSub_2: false,
        layer61OptionSub_3: false,
        layer61OptionSub_4: true,
        options: {},
        sensorId: this.$route.params.id,
        period: 'FROM',
        queryPeriod: 'h',
        dateFrom: this.filterPeriod ? this.filterPeriod[0] : null,
        dateTo: this.filterPeriod ? this.filterPeriod[1] : null
      };
      if (file === 'PDF') {
        payload.printCase = 4;
      }
      if (file === 'XLS') {
        payload.printCase = 3;
      }
      if (!file) {
        this.isLoading = true;
      }
      await new Api().getObjectChart(payload).then(res => {
        if (!file) {
          if (this.columnsGrid.length < 1) {
            this.headings.forEach(el => {
              this.columnsGrid.push(el.colSize);
            });
          }

          this.list = res.all.measures.map(el => ({
            id: el.id,
            columns: {
              ...this.headings.reduce((acc, item) => {
                acc[item.field] = el[item.field] || null;
                return acc;
              }, {})
            },
            errors: {
              q0_data: !!el?.error || null
            }
          }));
        }
        if (res?.url) {
          window.open(res.url, '_blank');
        }
      }).finally(() => {
        this.isLoading = false;
      });
    }
  }
};
</script>

<style scoped lang="scss">

</style>
