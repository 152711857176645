import ApiService from './ApiService';

export default class Api extends ApiService {
  async getUserInfo() {
    const { data } = await this._axios.get('nginxApi.php?get=user');
    // const {data} = await this._axios.get(`/auth/rest/user`);
    return data;
  }

  async getAddress(payload) {
    const { data } = await this._axios.post('/mob_ajax.php?action=geosearch', payload);
    return data;
  }

  async getUserAccesses() {
    const { data } = await this._axios.get('/auth/rest/access/HEAT');
    return data;
  }

  async getCityId() {
    const { data } = await this._axios.get('/nginxApi.php?get=config');
    return data;
  }

  // objects
  async getAllObjects() {
    const { data } = await this._axios.post('/ajax.php?action=getHeat', {
      withHeatSeasonHiddenColumns: 1,
      withHeatOptions: 1,
      layer61OptionSub_1: 1,
      layer61OptionSub_2: 1,
      layer61OptionSub_3: 1,
      dontFormatCalibrationDate: 1
    });
    return data;
  }

  async getObjectById(id) {
    const { data } = await this._axios.post('/ajax.php?action=getHeat', {
      sensorId: id,
      withHeatSeasonHiddenColumns: 1,
      layer61OptionSub_1: 1,
      layer61OptionSub_2: 1,
      layer61OptionSub_3: 1,
      dontFormatCalibrationDate: 1
    });
    return data;
  }

  async getObjectChart(payload) {
    const { data } = await this._axios.post('/ajax.php?action=getHeat', payload);
    return data;
  }

  async operateObject(payload) {
    const { data } = await this._axios.post('/ajax.php?action=operateHeat', {
      ...payload,
      currentMainMenuNumber: 61
    });
    return data;
  }

  async operateHeatSeasons(payload) {
    const { data } = await this._axios.post('ajax.php?action=operateHeatSeasons', payload);
    return data;
  }

  async getCityFastOptions(payload) {
    const { data } = await this._axios.post('/ajax.php?action=getCityFastOptions', {
      ...payload
    });
    return data;
  }

  async setCityFastOptions(payload) {
    const { data } = await this._axios.post('/ajax.php?action=setCityFastOptions', {
      ...payload
    });
    return data;
  }

}
