export const getNumberOfDays = endDate => {
  const date1 = new Date();
  const date2 = new Date(endDate);
  const oneDay = 1000 * 60 * 60 * 24;
  const diffInTime = date2.getTime() - date1.getTime();
  const diffInDays = Math.round(diffInTime / oneDay);
  return diffInDays;
};
export const getEmptyField = (details, arr = []) => {
  for (const elem in details) {
    if (!details[elem]) arr.push(elem);
  }
  return arr;
};
export const mappedTableList = arr => [...arr].map(item => ({
  id: item.id,
  organizationId: item.id,
  status: '0',
  isAlignment: item.alignment === 'checked',
  details: {
    title: item.details?.title,
    // inn: item.details.inn,
    phone: item.details?.confidant?.phone || '',
    email: item.details?.confidant?.email || '',
    ceoName: item.details?.confidant?.fio,
    address: item.details?.address
  },
  dragIndex: item.details?.dragIndex,
  errored_fields: getEmptyField({
    ceoName: item.details?.confidant?.fio
  }) // названия пустых полей
}));
export const getDayAddition = num => {
  const preLastDigit = num % 100 / 10;
  if (preLastDigit === 1) {
    return 'дней';
  }
  switch (num % 10) {
    case 1:
      return 'день';
    case 2:
    case 3:
    case 4:
      return 'дня';
    default:
      return 'дней';
  }
};
export const formattedFiles = files => {
  const formattedFiles = [];

  files.forEach(el => {
    if (el?.files?.length) {
      const fileList = el.files.map(el => ({
        contentType: el.type,
        filename: el.name, // .split('.')[0]
        icon: false,
        url: el.url
      }));
      formattedFiles.push(...fileList);
    } else {
      formattedFiles.push(el);
    }
  });
  return formattedFiles;
};
export const wordMatch = (left, right) => {
  // 0 - полное совпадение
  // 1 - нет совпадений
  left = left.toLowerCase();
  right = right.toLowerCase();

  if (left.includes(right)) {
    return 0;
  }
  left = `\b\b${left.toLowerCase()}\f\f`;
  right = `\b\b${right.toLowerCase()}\f\f`;

  let dist = -4;

  for (let i = 0; i < left.length - 2; ++i) {
    if (!right.includes(left.slice(i, i + 3))) ++dist;
  }

  for (let i = 0; i < right.length - 2; ++i) {
    if (!left.includes(right.slice(i, i + 3))) ++dist;
  }

  return Math.max(0, dist) / (left.length + right.length - 8);
};
export const formattedDate = (date, format = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric'
}) => {
  if (!date) return;
  return new Date(date).toLocaleString('Ru-ru', format);
};
export const getErrors = item => {
  const errors = {};
  // el.postgresErrorT3 && !el.postgresErrorP1 && !el.postgresErrorP2
  if (item?.postgresErrorT3) {
    errors.postgresErrorT3 = item.postgresErrorT3;
  }
  if (item?.postgresErrorP1) {
    errors.postgresErrorP1 = item.postgresErrorP1;
  }
  if (item?.postgresErrorP2) {
    errors.postgresErrorP2 = item.postgresErrorP2;
  }
  /* if (item.calibrationDaysLeft < 0) {
    errors.calibrationDate = 'Прошла дата поверки';
  } */
  return errors;
};
export const declOfNum = (number, words) => words[(number % 100 > 4 && number % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(number % 10 < 5) ? Math.abs(number) % 10 : 5]];
