<template>
  <div>
    <!-- <section
      class="r-textarea"
      style="height: auto!important"
    >
      <textarea
        v-model="JSON.stringify(info, null, 2)"
        id="settings_textarea"
        :rows="rows"
        class="r-textarea__textarea"
        style="overflow-y: auto; "
        @blur="saveSettings"
      />
      <label
        for="settings_textarea"
        class="r-textarea__label active"
      >
        Конфигурация HeatOptions
      </label>
    </section>-->
    <r-input
      class="mt-6"
      label="Postgres IP-адрес без http"
      v-model="info.postgreDBhost"
    />
    <r-input
      class="mt-6"
      label="Postgres порт"
      v-model="info.postgreDBport"
    />
    <r-input
      class="mt-6"
      label="Postgres имя БД"
      v-model="info.postgreDBname"
    />
    <r-input
      class="mt-6"
      label="Postgres логин"
      v-model="info.postgreDBuser"
    />
    <r-input
      class="mt-6"
      label="Postgres пароль"
      v-model="info.postgreDBpassword"
    />
    <div class="flex align-items-center mt-6">
      <r-checkbox
        class="mr-6"
        title="Высвечивать ошибку, если P1>P3"
        v-model="info.errorIfP1greaterP3"
      />
      <r-checkbox
        class="mr-6"
        title="Высвечивать ошибку, если P2>P3"
        v-model="info.errorIfP2greaterP3"
      />
      <r-checkbox
        class="mr-6"
        title="Высвечивать ошибку, если P2>P1"
        v-model="info.errorIfP2greaterP1"
      />
    </div>
    <r-input
      class="mt-6"
      label="Высвечивать ошибку, если T3 больше чем"
      v-model="info.errorIfT3greaterThan"
    />
    <r-input
      class="mt-6"
      label="Игнорировать ошибку P2, если P1 меньше чем"
      v-model="info.noP2ErrorIfP1lessThan"
    />
    <r-checkbox
      class="mt-6"
      title="Управление целевой t"
      v-model="info.useTargetTemp"
    />
    <r-checkbox
      class="mt-6"
      title="Тестировать наполнение БД (симуляция)"
      v-model="info.fillHeatCountersDemo"
    />
    <r-input
      class="mt-6"
      label="useTargetTemp_hint"
      v-model="info.useTargetTemp_hint"
    />
    <r-checkbox
      class="mt-6"
      title="test"
      v-model="info.test"
    />
    <r-button
      class="mt-6"
      @click="saveSettings"
      title="Сохранить"
      width="wide"
    />
    <p
      v-if="success"
      class="color-matrix mozzarella mt-2"
    >
      {{ success }}
    </p>
    <p
      v-if="error"
      class="color-fargo mozzarella mt-2"
    >
      {{ error }}
    </p>
    <!--<p class="mt-4 feta">
      Введите любые значения в формате JSON
    </p>-->
  </div>
</template>

<script>
import _ from 'lodash';
import Api from '../api/Api';

export default {
  name: 'TabSettings',
  data() {
    return {
      info: {
        postgreDBhost: null,
        postgreDBport: null,
        postgreDBname: null,
        postgreDBuser: null,
        postgreDBpassword: null,
        useTargetTemp: null,
        errorIfT3greaterThan: null,
        errorIfP1greaterP3: false,
        errorIfP2greaterP3: false,
        errorIfP2greaterP1: false,
        noP2ErrorIfP1lessThan: null,
        fillHeatCountersDemo: false,
        useTargetTemp_hint: null,
        test: false
      },
      rows: 15,
      defaultData: null,
      error: null,
      success: null
    };
  },
  created() {
    this.setData();
  },
  methods: {
    async setData() {
      await new Api().getCityFastOptions({
        fields: ['heatOptions']
      }).then(res => {
        this.defaultData = res.heatOptions;
        this.rows = Object.keys(res.heatOptions).length + 2;
        // this.info = JSON.stringify(res.heatOptions, null, 2);
        this.info = { ...this.info, ...res.heatOptions };
      });
      // this.info = this.$store.state.object.all
    },
    async saveSettings() {
      try {
        this.error = null;
        new Api().setCityFastOptions({
          heatOptions: this.info
        }).then(() => {
          this.success = 'Сохранено';
          setTimeout(() => {
            this.success = '';
          }, 3000);
        });
      } catch (err) {
        this.error = err;
      }
    }
  }
};
</script>

<style scoped>
::v-deep textarea:focus {
  background: transparent !important;
}
</style>
