<template>
  <div>
    <div class="flex mb-10">
      <div class="flex-1">
        <p class="burrata mb-6">
          Температурный режим
        </p>
        <div class="flex">
          <!--<div class="mozzarella mr-6">
            <span class="opacity-48 mb-1 flex">t фактическая, °C</span>
            {{
              /*data.tfact ||*/
              '—'
            }}
          </div>-->
          <div class="mozzarella">
            <span class="opacity-48 mb-1 flex">t фактическая, °C</span>
            {{
              data.tfact || '—'
            }}
          </div>
        </div>
      </div>
      <div class="flex-1">
        <p class="burrata mb-6">
          Горячее водоснабжение
        </p>
        <div class="flex">
          <div class="mozzarella mr-6">
            <span class="opacity-48 mb-1 flex">t3, °C</span>
            {{ data.t3_data || '—' }}
          </div>
          <div class="mozzarella mr-6">
            <span class="opacity-48 mb-1 flex">ГВС, м3</span>
            {{ data.qg_data || '—' }}
          </div>
          <div class="mozzarella">
            <span class="opacity-48 mb-1 flex">Qг, Гкал</span>
            {{ data.qg_data || '—' }}
          </div>
        </div>
      </div>
    </div>
    <div class="flex mb-10">
      <div class="flex-1">
        <p class="burrata mb-6">
          Мгновенный расход
        </p>
        <div class="grid">
          <div class="grid_item">
            <p class="bryndza mb-3">
              Температура
            </p>
            <div class="flex">
              <div class="mozzarella mr-6">
                <span class="opacity-48 mb-1 flex">t1, °C</span>
                {{ data.t1_data || '—' }}
              </div>
              <div class="mozzarella mr-6">
                <span class="opacity-48 mb-1 flex">t2, °C</span>
                {{ data.t2_data || '—' }}
              </div>
              <div class="mozzarella mr-6">
                <span class="opacity-48 mb-1 flex">t3, °C</span>
                {{ data.t3_data || '—' }}
              </div>
              <div class="mozzarella">
                <span class="opacity-48 mb-1 flex">dt, °C</span>
                {{ data.dt_data || '—' }}
              </div>
            </div>
          </div>
          <div class="grid_item">
            <p class="bryndza mb-3">
              Объёмы
            </p>
            <div class="flex">
              <div class="mozzarella mr-6">
                <span class="opacity-48 mb-1 flex">V1, м3</span>
                {{ data.v1_data || '—' }}
              </div>
              <div class="mozzarella mr-6">
                <span class="opacity-48 mb-1 flex">V2, м3</span>
                {{ data.v2_data || '—' }}
              </div>
              <div class="mozzarella mr-6">
                <span class="opacity-48 mb-1 flex">V3, м3</span>
                {{ data.v3_data || '—' }}
              </div>
            </div>
          </div>
          <div class="grid_item">
            <p class="bryndza mb-3">
              Расход тепла
            </p>
            <div class="flex">
              <div class="mozzarella">
                <span class="opacity-48 mb-1 flex">Qтв, Гкал</span>
                {{ data.q0_cur_fin || '—' }}
              </div>
            </div>
          </div>
          <div class="grid_item">
            <p class="bryndza mb-3">
              Давление
            </p>
            <div class="flex">
              <div class="mozzarella mr-6">
                <span class="opacity-48 mb-1 flex">P1, кгс/см²/</span>
                {{ data.p1_data || '—' }}
              </div>
              <div class="mozzarella mr-6">
                <span class="opacity-48 mb-1 flex">P2, кгс/см²</span>
                {{ data.p2_data || '—' }}
              </div>
              <div class="mozzarella mr-6">
                <span class="opacity-48 mb-1 flex">P3, кгс/см²</span>
                {{ data.p3_data || '—' }}
              </div>
            </div>
          </div>
          <div class="grid_item">
            <p class="bryndza mb-3">
              Масса
            </p>
            <div class="flex">
              <div class="mozzarella mr-6">
                <span class="opacity-48 mb-1 flex">М1, т</span>
                {{ data.m1_data || '—' }}
              </div>
              <div class="mozzarella mr-6">
                <span class="opacity-48 mb-1 flex">М2, т</span>
                {{ data.m2_data || '—' }}
              </div>
              <div class="mozzarella mr-6">
                <span class="opacity-48 mb-1 flex">М3, т</span>
                {{ data.m3_data || '—' }}
              </div>
            </div>
          </div>
          <div class="grid_item">
            <p class="bryndza mb-3">
              Условные значения
            </p>
            <div class="flex">
              <div class="mozzarella mr-6">
                <span class="opacity-48 mb-1 flex">tx, C</span>
                {{ data.tx || '—' }}
              </div>
              <div class="mozzarella">
                <span class="opacity-48 mb-1 flex">Px, кгс/см2</span>
                {{ data.Px || '—' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="burrata mb-6">
      Статистика и отчётность
    </p>
    <div class="flex align-items-center">
      <r-select
        class="flex-1 mr-6"
        :items="$store.state.object.reportData"
        label="Отчёт"
        v-model="selectedReport"
        @input="setData(null)"
      />
      <r-select
        class="flex-1 mr-6"
        :items="$store.state.object.queryPeriodList"
        label="Выборка из базы данных"
        v-model="queryPeriod"
        @input="setData(null)"
      />
      <!--  <div class="relative flex-1 mr-6">
        <r-select
          @click="showPeriodDate = true"
          :items="[
            ...period,
            selectedPeriodTitle
          ]"
          label="Период"
          v-model="selectedPeriod"
          @input="inputPeriod"
        />
        <r-date-picker
          v-if="selectedPeriod === 'FROM' && showPeriodDate"
          class="absolute date_selected"
          period
          inline
          v-model="periodDate"
          @input="inputDate"
        />
      </div> -->
      <!--<select-with-date
        class="flex-1"
        :items="$store.state.object.period"
        label="Период"
        v-model="selectedPeriod"
        @input="inputPeriod"
        @changeDate="changeDate"
      />-->
      <date-picker-all-slots
        class="flex-1"
        @input="changeDate"
        v-model="periodDate"
      />
    </div>
    <drop-down-upload-button
      :items="['PDF', 'XLS']"
      class="mt-6"
      @selected="selectedDownloadFile"
    >
      <template>
        <r-button
          transparent
          class="color-rocky"
        >
          <r-icon
            size="16"
            class="mr-2"
            icon="file-download"
            fill="rocky"
          />
          Скачать отчёт
        </r-button>
      </template>
    </drop-down-upload-button>
    <div class="chart mt-6">
      <apexchart
        type="area"
        :options="chartOptions"
        :series="series"
        height="350"
      />
    </div>
  </div>
</template>

<script>
import apexchart from 'vue-apexcharts';
import SelectWithDate from './SelectWithDate';
import Api from '../api/Api';
import DropDownUploadButton from './DropDownUploadButton';
import DatePickerAllSlots from './DatePickerAllSlots';

const ru = {
  name: 'ru',
  options: {
    months: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь'
    ],
    shortMonths: [
      'Янв',
      'Фев',
      'Мар',
      'Апр',
      'Май',
      'Июн',
      'Июл',
      'Авг',
      'Сен',
      'Окт',
      'Ноя',
      'Дек'
    ],
    days: [
      'Воскресенье',
      'Понедельник',
      'Вторник',
      'Среда',
      'Четверг',
      'Пятница',
      'Суббота'
    ],
    shortDays: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    toolbar: {
      exportToSVG: 'Сохранить SVG',
      exportToPNG: 'Сохранить PNG',
      exportToCSV: 'Сохранить CSV',
      menu: 'Меню',
      selection: 'Выбор',
      selectionZoom: 'Выбор с увеличением',
      zoomIn: 'Увеличить',
      zoomOut: 'Уменьшить',
      pan: 'Перемещение',
      reset: 'Сбросить увеличение'
    }
  }
};

export default {
  name: 'ObjectTabMonitoring',
  components: {
    apexchart,
    SelectWithDate,
    DropDownUploadButton,
    DatePickerAllSlots
  },
  props: {
    data: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      isLoading: true,
      selectedReport: 'heat',
      queryPeriod: 'h',
      selectedPeriod: 'WEEK',
      periodDate: ['2022-11-07', '2022-11-07'],
      showPeriodDate: true,
      chartData: {},
      series: [{
        name: 'STOCK ABC',
        data: []
      }],
      chartOptions: {
        chart: {
          locales: [ru],
          defaultLocale: 'ru',
          type: 'area',
          height: 400,
          zoom: {
            enabled: true
          }
        },
        noData: {
          text: 'Нет данных за выбранный период',
          style: {
            color: 'rgba(4, 21, 62, 0.49)',
            fontSize: '16px',
            fontFamily: 'Golos UI'
          }
        },
        tooltip: {
          x: {
            format: 'dd.MM.yyyy HH:mm'
          }

        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 2
        },
        xaxis: {
          type: 'datetime',
          labels: {
            datetimeUTC: false,
            style: {
              colors: ['#878fa2'],
              fontSize: '10px',
              fontFamily: 'Golos UI',
              fontWeight: 400,
              cssClass: 'apexcharts-yaxis-label'
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              colors: ['#878fa2'],
              fontSize: '10px',
              fontFamily: 'Golos UI',
              fontWeight: 400,
              cssClass: 'apexcharts-yaxis-label'
            }
          }
        },
        annotations: {

        },
        legend: {
          horizontalAlign: 'left'
        },
        fill: {
          colors: ['#c0d6f6', '#5fad05', '#e78018']
        },
        colors: ['#81ABEE', '#5fad05', '#e78018'],
        grid: {
          borderColor: '#E4EDFB',
          row: {}
        }
      },
      chartLegend: ''
    };
  },
  async mounted() {
    await this.setData();
  },
  methods: {
    selectedDownloadFile(file) {
      this.setData(file);
    },
    changeDate({ date, period }) {
      this.periodDate = date;
      this.selectedPeriod = period;
      this.setData();
      // this.showPeriodDate = false
    },
    inputPeriod(el) {
      if (el !== 'FROM') {
        this.setData();
      }
    },
    hidePeriodDate(e) {
      console.log(e, 'sadsadasdas', this.selectedPeriod);
      //  this.showPeriodDate = false;
    },
    async setData(file = null) {
      this.isLoading = true;
      const payload = {
        sensorId: this.$route.params.id,
        action: 'chart',
        period: this.selectedPeriod,
        sourceName: this.selectedReport, // обяз, например heat_t123 - см ниже
        // interval: 'DAY',
        queryPeriod: this.queryPeriod
      };
      if (this.selectedPeriod === 'FROM') {
        payload.dateFrom = this.periodDate[0];
        payload.dateTo = this.periodDate[1];
      }
      if (file === 'PDF') {
        payload.printCase = 5;
        // if (this.queryPeriod === 'h') {
        //   payload.printCase = 4;
        // } else if (this.queryPeriod === 'd') {
        //   payload.printCase = 2;
        // } else {
        //   payload.printCase = 5;
        // }
      }
      if (file === 'XLS') {
        payload.printCase = 6;
        // if (this.queryPeriod === 'h') {
        //   payload.printCase = 3;
        // } else if (this.queryPeriod === 'd') {
        //   payload.printCase = 1;
        // } else {
        //  payload.printCase = 6;
        // }
      }
      if (file) {
        payload.meterChartPrintOptionTitle = 1;
        payload.meterChartPrintOptionTotal = 1;
        payload.meterChartPrintOptionAddEdgeValues = 1;
        payload.meterChartPrintOptionDate = 1;
        payload.meterChartPrintOptionAgreementNo = 1;
      }
      await new Api().getObjectChart(payload).then(res => {
        if (!file) {
          this.chartLegend = res?.all?.legends?.measures ? res?.all?.legends?.measures : this.chartLegend;
          this.series = [{
            data: res?.all?.measures ? res.all.measures.map(el => [
              Number(el.ts) * 1000,
              el.qo || el.data
            ]) : [],
            name: this.chartLegend || 'График'
          }];
          if (res?.all?.measures2) {
            this.series.push({
              data: res?.all?.measures2 ? res.all.measures2.map(el => [
                Number(el.ts) * 1000,
                el.qo || el.data
              ]) : [],
              name: res?.all?.legends?.measures2 || 'График 2'
            });
          }
          if (res?.all?.measures3) {
            this.series.push({
              data: res?.all?.measures3 ? res.all.measures3.map(el => [
                Number(el.ts) * 1000,
                el.qo || el.data
              ]) : [],
              name: res?.all?.legends?.measures3 || 'График 3'
            });
          }
          if (this.selectedReport === 'heat_v12_inacc') {
            this.chartOptions = {
              annotations: {
                yaxis: [{
                  y: 0,
                  borderColor: '#F00000',
                  label: {
                    text: 'Превышение погрешности',
                    style: {
                      color: '#fff',
                      background: '#F00000'
                    }
                  }
                }]
              },
              tooltip: {
                y: {
                  formatter(value) {
                    return `${value.toFixed(2)}%`;
                  }
                }
              },
              yaxis: {
                labels: {
                  style: {
                    colors: ['#878fa2'],
                    fontSize: '10px',
                    fontFamily: 'Golos UI',
                    fontWeight: 400,
                    cssClass: 'apexcharts-yaxis-label'
                  },
                  formatter: value => `${value.toFixed(2)}%`
                }
              }
            };
          } else {
            this.chartOptions = {
              annotations: {
                yaxis: []
              },
              tooltip: {},
              yaxis: {
                labels: {
                  style: {
                    colors: ['#878fa2'],
                    fontSize: '10px',
                    fontFamily: 'Golos UI',
                    fontWeight: 400,
                    cssClass: 'apexcharts-yaxis-label'
                  },
                  formatter: value => value.toFixed(3)
                }
              }
            };
          }
        }
        if (res?.url) {
          window.open(res.url, '_blank');
        }
      }).finally(() => {
        this.isLoading = false;
      });
    }
  }
};
</script>

<style scoped lang="scss">
.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
}

.chart {
  border: 1px solid #E4EDFB;
  border-radius: 16px;
  padding: 15px;
}
</style>
