<template>
  <r-popover
    class="relative"
    v-click-outside="clickOutside"
    :is-open="isOpen"
    @input="onChangeShow"
  >
    <template #activator="{ on }">
      <r-button-action
        @click.native="on.click"
        icon="file-download"
        title="Скачать отчет"
        is-arrow
        :value="isOpen"
      />
    </template>

    <template #content>
      <div
        class="list"
        :class="{'top': top}"
      >
        <div
          class="item sulguni"
          @click="selectItem(item)"
          v-for="item in items"
          :key="item"
        >
          {{ item }}
        </div>
      </div>
    </template>
  </r-popover>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'DropDownUploadButton',
  directives: {
    ClickOutside
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    top: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: false
    };
  },

  methods: {
    onChangeShow(val) {
      this.isOpen = val;
    },
    clickOutside() {
      // if (this.isOpen && e.target.closest('.btn-dropdown-list') === null) {
      this.isOpen = false;
      // }
    },
    selectItem(item) {
      this.$emit('selected', item);
      this.isOpen = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.r-drop-down{
  display: inline-block;
}

.relative {
  position: relative;
}
// .disabled {
//   opacity: 0.72;
// }
.list {
  display: inline-flex;
  flex-direction: column;
  z-index: 10000;
}
.item {
  display: flex;
  white-space: nowrap;
  align-items: center;
  opacity: 0.72;
  padding: 14px 16px;
  position: relative;
  &:hover {
    opacity: 1;
    cursor: pointer;
    &::before {
      content: '';
      position: absolute;
      background-color: #3d75e4;
      height: calc(100% - 8px);
      width: 4px;
      top: 4px;
      left: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}
.item--active {
  opacity: 1;
  &::before {
    content: '';
    position: absolute;
    background-color: #3d75e4;
    height: calc(100% - 8px);
    width: 4px;
    top: 4px;
    left: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
::v-deep .r-drop-down__activator{
  flex-direction: row-reverse;
  cursor: pointer;
  min-height: 0;
  .r-drop-down__arrow{
    margin-right: 0;
    margin-left: 8px;
  }
}
</style>
