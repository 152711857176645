<template>
  <div class="download_modal">
    <p class="camembert mb-10">
      Скачать отчет
    </p>
    <r-select
      class="flex-1 mb-6"
      :items="$store.state.object.queryPeriodList"
      label="Выборка из базы данных"
      v-model="queryPeriod"
    />
    <div class="flex align-items-center mb-6">
      <r-date-picker
        class="flex-1 mr-6"
        :class="{ field_error: $v.dateFrom.$error }"
        label="C"
        v-model="dateFrom"
        click-close
        :error="$v.dateFrom.$error && !$v.dateFrom.required"
        error-message="Поле обязательно для заполнения"
      />
      <r-date-picker
        label="По"
        :start-date="dateFrom"
        class="flex-1"
        v-model="dateTo"
        click-close
      />
    </div>

    <r-select
      class="flex-1 mb-1"
      :class="{ field_error: $v.selectedFormat.$error }"
      :items="[
        {
          id: 'PDF',
          title: 'PDF'
        },
        {
          id: 'XLS',
          title: 'XLS'
        }
      ]"
      label="Формат отчёта"
      v-model="selectedFormat"
      :error="$v.selectedFormat.$error && !$v.selectedFormat.required"
      error-message="Поле обязательно для заполнения"
    />
    <r-button
      @click.native="submit"
      class="mt-10"
      :title="'Скачать ' + selectedFormat"
      width="wide"
    />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import Api from '../api/Api';

export default {
  name: 'DownloadReportModal',
  components: {
  },
  props: {
    sensorId: {
      type: [String, Number]
    }
  },
  data() {
    return {
      selectedReport: 'heat',
      selectedFormat: 'PDF',
      selectedPeriod: 'FROM',
      queryPeriod: 'h',
      error: false,
      dateFrom: '',
      dateTo: ''
    };
  },
  validations: {
    selectedFormat: {
      required
    },
    dateFrom: {
      required
    }
  },
  methods: {
    changeDate(date) {
      this.periodDate = date;
      this.submit();
      // this.showPeriodDate = false
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$nextTick(() => {
          document.querySelector('.field_error').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        });
      } else {
        const file = this.selectedFormat;
        this.isLoading = true;
        const payload = {
          sensorId: this.$props.sensorId,
          period: 'FROM',
          sourceName: 'heat', // обяз, например heat_t123 - см ниже
          interval: 'DAY',
          dateFrom: this.dateFrom,
          dateTo: this.dateTo
        };
        if (file === 'PDF') {
          if (this.queryPeriod === 'h') {
            payload.printCase = 4;
          } else if (this.queryPeriod === 'd') {
            payload.printCase = 2;
          } else {
            payload.printCase = 5;
          }
        }
        if (file === 'XLS') {
          if (this.queryPeriod === 'h') {
            payload.printCase = 3;
          } else if (this.queryPeriod === 'd') {
            payload.printCase = 1;
          } else {
            payload.printCase = 6;
          }
        }
        await new Api().getObjectChart(payload).then(res => {
          if (res?.url) {
            window.open(res.url, '_blank');
          }
        }).finally(() => {
          this.isLoading = false;
        });
      }
    }
  }
};
</script>

<style scoped lang="scss">
.download_modal{
  width: 100%;
  max-width: 585px;
  margin: 0 auto;
}
.field_wrapper {
  position: relative;

  .field_error {
    &.r-input {
      background: rgba(#e14761, 0.08);
    }

    &.r-textarea {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .r-input {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .r-drag-upload-file {
      border-color: #e14761;
    }
  }

  .error {
    position: absolute;
    font-size: 12px;
    top: 1px;
    right: 16px;
    pointer-events: none;

    &.bottom {
      top: calc(100% + 1px);
      right: unset;
      left: 16px;
    }
  }
}
</style>
