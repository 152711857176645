var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"objects"},[_c('div',{staticClass:"flex mb-6 align-items-center"},[_c('r-date-picker',{staticClass:"flex-1 mr-6",attrs:{"click-close":true,"period":true,"label":"Период","start-date":null,"end-date":null},model:{value:(_vm.period),callback:function ($$v) {_vm.period=$$v},expression:"period"}}),_c('r-select',{staticClass:"flex-1",attrs:{"items":[
        {
          id: 1,
          title: 'По умолчанию'
        },
        {
          id: 2,
          title: 'По дате'
        },
      ],"label":"Сортировка"},model:{value:(_vm.selectedSort),callback:function ($$v) {_vm.selectedSort=$$v},expression:"selectedSort"}})],1),(_vm.isLoading)?_c('loader'):[_c('calendar',{attrs:{"period":_vm.period,"selected-sort":_vm.selectedSort}})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }