<template>
  <div class="objects">
    <div class="flex mb-6">
      <router-link
        class="flex align-items-center sulguni color-rocky mr-6"
        to="add"
        v-if="this.$store.getters.userIsRoot"
      >
        <r-icon
          size="16"
          fill="rocky"
          class="mr-2"
          icon="add"
        />
        Добавить вручную
      </router-link>
    </div>
    <div class="flex mb-6 align-items-center">
      <r-select
        class="flex-1 mr-6"
        :items="statuses"
        label="Статус"
        v-model="selectedStatus"
      />
      <r-input
        class="flex-1 mr-6"
        label="Поиск"
        v-model.trim="search"
        before-icon="search"
      />

      <r-button-simple
        size="larishae"
        icon="icon-view"
        icon-size="20"
        @click="changeView('list')"
        :type="selectedView === 'list' ? 'primary' : 'secondary'"
      />
      <r-button-simple
        size="larishae"
        icon="menu"
        class="ml-2"
        icon-size="20"
        @click="selectedView = 'table'"
        :type="selectedView === 'table' ? 'primary' : 'secondary'"
      />
      <r-button-simple
        size="larishae"
        class="ml-2"
        icon="geopoint"
        :type="selectedView === 'map' ? 'primary' : 'secondary'"
        icon-size="20"
        @click="selectedView = 'map'"
      />
    </div>
    <loader v-if="isLoading" />
    <template v-else>
      <div class="flex mt-2 mb-2">
        <div class="ml-auto parmigiano opacity-48">
          {{ !!searchText ? "Найдено" : "Всего" }} {{ countObjects }}
        </div>
      </div>
      <div
        v-if="selectedView === 'map'"
        class="objects__map mt-6"
        ref="map"
      >
        <rir-map
          v-if="isShowMap"
          show-type
          @click="onMapClick"
        >
          <div v-if="filteredObjects.length > 0">
            <div
              v-for="(marker,index) of filteredObjects"
              :key="`_${marker.id}+${index}`"
            >
              <ymap-marker
                :coords="[marker.lat, marker.lng]"
                :marker-id="`marker_${marker.id}+${index}`"
                :icon="$markerIcon('mapPin')"
                :options="{
                  hideIconOnBalloonOpen: false,
                }"
                cluster-name="main"
                :balloon="{ body: clusterBalloon(marker)}"
                @click="onMarkerClick(marker.id)"
                @balloonopen="bindListener"
                @balloonclose="unbindListener"
              >
                <balloon-card
                  slot="balloon"
                  :marker="marker"
                  :data-id="marker.id"
                />
              </ymap-marker>
            </div>
          </div>
        </rir-map>
      </div>
      <div
        v-if="selectedView === 'list'"
        class="objects__list mt-6"
      >
        <card
          v-for="card of filteredObjects"
          :key="card.id"
          :card="card"
        />
        <not-found v-if="countObjects < 1" />
      </div>
      <div v-if="selectedView === 'table'">
        <scroll-table
          :list="filteredObjects"
          :headings="headings"
          :columns-grid="tableGridColumns"
          row-link="/edit/"
          start-sorted-column="name"
          query="?fromTab=general"
        />
      </div>
    </template>
  </div>
</template>

<script>

import NotFound from './NotFound';
import RirMap from './RirMap';
import ModalUpload from './ModalUpload';
import DeleteModal from './DeleteModal';
import BalloonCard from './BalloonCard';
import Card from './Card';
import Api from '../api/Api';
import { getNumberOfDays, wordMatch } from '../helpers/utils';
import ScrollTable from './Table';
import Loader from './Loader';

export default {
  name: 'TabGeneral',
  components: {
    Loader,
    Card,
    NotFound,
    RirMap,
    BalloonCard,
    ScrollTable
  },
  data() {
    return {
      timeoutId: null,
      selectedView: 'list',
      searchText: '',
      statuses: [
        {
          id: 'ALL',
          title: 'Все'
        },
        {
          id: 'NO_ERR',
          title: 'Без ошибок'
        },
        {
          id: 'YES_ERR',
          title: 'С ошибками'
        }
      ],
      selectedStatus: 'ALL',
      isShowMap: false,
      selectedMarkerId: null,
      // table
      headings: [
        {
          title: 'Наименование',
          field: 'name'
        },
        {
          title: 'Адрес',
          field: 'address'
        },
        {
          title: 'Поверка',
          field: 'calibrationDate'
        },
        {
          title: 'Уведомлять на имейл',
          field: 'calibrationEmail'
        }
      ],
      tableGridColumns: ['16px', '160px', '400px', '220px', '220px']
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.object.isLoading;
    },
    filteredObjects() {
      let list = this.selectedView === 'table'
        ? this.$store.getters.getObjectsForTableGeneral : this.$store.state.object.allObjects;
      // Если заполнена строка поиска
      if (this.searchText) {
        list = list.filter(
          el => wordMatch(el?.name, this.searchText) <= 0.5
            || wordMatch(el?.address, this.searchText) <= 0.5
        );
      }
      if (this.selectedStatus === 'NO_ERR') {
        list = list.filter(el => (!el?.postgresErrorT3 && !el?.postgresErrorP1 && !el?.postgresErrorP2)
          && getNumberOfDays(el.calibrationDate) >= 0);
      }
      if (this.selectedStatus === 'YES_ERR') {
        list = list.filter(el => (!!el?.postgresErrorT3 && !!el?.postgresErrorP1 && !!el?.postgresErrorP2)
          || getNumberOfDays(el.calibrationDate) < 0);
      }
      return list;
    },
    countObjects() {
      return this.filteredObjects.length;
    },
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          this.searchText = typeof val === 'string' ? val.toLowerCase() : null;
        }, 420);
      }
    }
  },
  activated() {
    this.isShowMap = true;
  },
  deactivated() {
    this.isShowMap = false;
  },
  methods: {
    changeView(type) {
      this.selectedView = type;
      if (type === 'map') {
        this.isShowMap = true;
      } else {
        this.isShowMap = true;
      }
    },
    clusterBalloon(marker) {
      return `
       <div class="r-map-balloon pointer pa-4">
        <p class="roquefort mb-1">
          ${marker.name}
        </p>
        <p class="сaprino opacity-72">
          ${marker.address}
        </p>
      </div>
      `;
    },
    async openDeleteModal(id) {
      await this.$rir.modal.open(DeleteModal, {
        title: 'Удалить объект?',
        action: () => {
          this.delete(id);
        }
      });
    },
    async delete(id) {
      await new Api().operateObject({
        id,
        action: 'purge'
      }).then(() => {
        const r = this.$router.resolve({
          name: 'index'
        });
        window.location.assign(r.href);
      });
    },
    openModalUpload() {
      this.$rir.modal.open(ModalUpload);
    },
    onMarkerClick(id) {
      this.selectedMarkerId = id;
    },

    onMapClick() {
      this.selectedMarkerId = null;
    },

    onBalloonClick() {
      if (!this.selectedMarkerId) return;

      this.$router.push({
        name: 'Edit',
        params: { id: this.selectedMarkerId }
      });
    },

    // Обрабочики для Balloon'a карты
    bindListener() {
      if (!this.selectedMarkerId) return;

      const balloon = this.$refs.map.querySelector(
        `.r-map-balloon[data-id="${this.selectedMarkerId}"]`
      );

      balloon.addEventListener('click', this.onBalloonClick);
    },
    unbindListener() {
      if (!this.selectedMarkerId) return;
      const balloon = this.$refs.map.querySelector(
        `.r-map-balloon[data-id="${this.selectedMarkerId}"]`
      );

      balloon.addEventListener('click', this.onBalloonClick);
    }

  }
};
</script>

<style scoped lang="scss">
.icon-close {
  cursor: pointer;
  position: absolute;
  margin-left: calc(100% - 60px);
  margin-top: -28px;
}

.icon-div {
  display: table;
  position: relative;
}

.objects {
  &__filter {
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}

::v-deep .r-popover {
  display: flex;
  align-items: center;

  &__activator {
    width: 100%;
  }

  .r-icon {
    &.add-icon {
      margin-right: 8px;
    }

    &.data-download__arrow {
      margin-left: 8px;
    }
  }
}

.data-download {
  &__wrapper {
    padding: 12px;

    .r-button {
      display: flex;
      align-items: center;

      .r-icon {
        margin-right: 8px;
      }
    }
  }
}

::v-deep .ya-map-point {
  position: absolute;
  left: -4px;
  top: -4px;
  display: block;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid var(--rir-rocky);
  border-radius: 50%;
}

::v-deep .ya-map-point--edge {
  cursor: pointer !important;
  position: absolute;
  left: -3px;
  top: -3px;
  display: block;
  width: 6px;
  height: 6px;
  background-color: var(--rir-rocky);
  border-radius: 50%;
  opacity: 0.8;
}

</style>
