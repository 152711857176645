<template>
  <r-popover
    content-class="r-select__content r-popover__radius"
    :disabled="disabled"
    v-model="showSelect"
  >
    <template #activator="{ on }">
      <r-icon
        size="16"
        fill="rocky"
        class="pointer"
        icon="settings"
        @click.native="on.click"
      />
    </template>
    <template #content>
      <div class="scroll">
        <r-list-item
          v-for="checkbox of items"
          :key="checkbox.field"
          id-value="field"
          return-object
          :val="checkbox"
          :title="checkbox.title"
          @input="changeCheckbox($event, checkbox)"
          v-model="choicest"
          @click="allChoice = false"
        />
      </div>
    </template>
  </r-popover>
</template>

<script>
export default {
  name: 'SelectCheckboxes',
  components: {},
  props: {
    items: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      default: 'Выбрать'
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isChoicest: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    showSelect: false,
    item: null,
    choicest: [],
    allChoice: false
  }),
  computed: {
    inputValues() {
      /*   const arr = this.choicest.map(item => item.value);
      return arr.join('; '); */
      if (this.choicest.length > 1) {
        return `Выбрано: ${this.choicest.length}`;
      }
      return this?.choicest[0]?.value || '';
    }
  },
  watch: {},
  created() {
    this.choicest = this.$props.isChoicest;
  },
  methods: {
    changeCheckbox(e, checkbox) {
      this.$emit('input', this.choicest);
      const isHidden = this.choicest.find(el => el.field === checkbox.field);
      this.$emit('change', {
        field: checkbox.field,
        hidden: !!!isHidden
      });
    },
    choiceAll() {
      if (this.allChoice) {
        this.choicest = this.$props.items;
      } else {
        this.choicest = [];
      }
    }
  }
};
</script>
<style lang="scss">
.opacity {
  opacity: 1;
}

.r-input__input input {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.r-list > .r-list-item * {
  opacity: 1 !important;
}
.scroll {
  overflow-y: auto;
  max-height: 200px;
  scrollbar-color: rgba(#04153E, 0.08) transparent;

  &::-webkit-scrollbar-track {
    width: 4px;
    background: #FFFFFF;
  }

  &::-webkit-scrollbar {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.72;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    background: #e4edfb;
    border-radius: 2px;
    opacity: 0.72;
  }
}
</style>
