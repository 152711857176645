<template>
  <r-popover
    v-model="activePicker"
    position="right-bottom"
    @close="dropModel"
  >
    <template #activator="{on}">
      <div
        @click="open(on)"
        tabindex="0"
        class="RSelect__input"
      >
        <div class="RSelect__backdoor" />
        <div
          class="RInput default eluno readonly"
          :class="{'isOpen': activePicker}"
        >
          <div class="RInput__body is-label">
            <div class="RInput__input">
              <input
                type="text"
                disabled="disabled"
                autocomplete="new-password"
                :value="periodModel.title ? periodModel.title : getValueInput"
              >
              <div
                class="RInput__label"
                :class="{'active': !!periodModel}"
                v-text="'Период'"
              />
            </div>
          </div>
          <a class="RInput__after">
            <r-icon
              icon="arrow-down"
              size="16"
            />
          </a>
        </div>
      </div>
    </template>
    <template #content>
      <div
        class="pa-4"
        id="picker"
      >
        <r-date-picker
          v-model="model"
          period
          inline
          @input="checkInputDate"
        >
          <template #header>
            <r-select
              label="Период"
              :items="itemsPeriod"
              v-model="periodModel"
              @change="setModel"
              return-object
            />
          </template>
          <template #footer>
            <!--<r-input
              :is-clear="false"
              class="mb-5 picker-input"
              label="Дата от и до"
              :value="getValueInput"
              @clearModel="model = null"
              readonly
            />-->
            <r-button
              title="Выбрать"
              width="wide"
              @click="sendEmit"
              :disabled="disabledButton"
            />
          </template>
        </r-date-picker>
      </div>
    </template>
  </r-popover>
</template>

<script>

export default {
  name: 'DatePickerAllSlots',
  data() {
    return {
      activePicker: false,
      model: null,
      periodModel: {
        id: 'WEEK',
        title: 'Неделя'
      },
      itemsPeriod: [
        {
          id: 'DAY',
          title: 'День'
        },
        {
          id: 'WEEK',
          title: 'Неделя'
        },
        {
          id: 'MONTH',
          title: 'Месяц'
        }
      ]
    };
  },
  computed: {
    disabledButton() {
      return !this.model || this.model.length < 2;
    },
    getValueInput() {
      if (this.model) {
        if (!this.model[1]) {
          return `${this.toLocaleUTCDateString(this.model[0], 'ru-Ru')}–__.__.____`;
        }
        return `${this.toLocaleUTCDateString(this.model[0], 'ru-Ru')}–${this.toLocaleUTCDateString(this.model[1], 'ru-Ru')}`;
      }
      return '__.__.____–__.__.____';
    }
  },
  mounted() {
    this.setModel();
  },
  methods: {
    open(on) {
      on.click();
      // this.activePicker = !this.activePicker;
    },
    checkInputDate() {
      this.periodModel = {
        id: 'FROM',
        title: null
      };
    },
    toLocaleUTCDateString(date, locales, options = {}) {
      let d = date;
      if (date) {
        // eslint-disable-next-line prefer-regex-literals
        const regexp = new RegExp('(\\d{4}-\\d{2}-\\d{2})', 'gm');
        d = date.match(regexp);
      }
      const adjustedDate = new Date(d);
      return adjustedDate.toLocaleDateString(locales, options);
    },
    setModel(val = this.periodModel) {
      let firstday = null;
      let lastday = null;
      switch (val.id) {
        case 'DAY':
          this.model = [new Date().toLocaleDateString('fr-CA'), new Date().toLocaleDateString('fr-CA')];
          this.sendEmit();
          break;
        case 'WEEK':
          // eslint-disable-next-line no-case-declarations
          const curr = new Date();
          // eslint-disable-next-line no-case-declarations
          const first = curr.getDate() - curr.getDay() + 1;
          // eslint-disable-next-line no-case-declarations
          const last = first + 6;

          firstday = new Date(curr.setDate(first)).toLocaleDateString('fr-CA');
          lastday = new Date(curr.setDate(last)).toLocaleDateString('fr-CA');
          this.model = [firstday, lastday];
          this.sendEmit();
          break;
        case 'MONTH':
          firstday = new Date(new Date().getFullYear(), new Date().getMonth(), 1)
            .toLocaleDateString('fr-CA');
          lastday = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
            .toLocaleDateString('fr-CA');
          this.model = [firstday, lastday];
          this.sendEmit();
          break;
        default:
          firstday = new Date(new Date().getFullYear(), 0, 1)
            .toLocaleDateString('fr-CA');
          lastday = new Date(new Date().getFullYear() + 1, 0, 0)
            .toLocaleDateString('fr-CA');
          this.model = [firstday, lastday];
      }
    },
    sendEmit() {
      this.activePicker = false;
      this.$emit('input', { date: this.model, period: this.periodModel.id });
    },
    dropModel() {
      //this.model = null;

      this.activePicker = false;
    }
  }
};
</script>

<style lang="scss">
.picker-input{
  &.RInput{
    opacity: 1;
  }
  & .RInput__clear.events {
    cursor: pointer;
    pointer-events: auto;
  }
}
</style>
