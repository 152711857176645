<template>
  <div
    class="table"
    ref="table"
  >
    <scroll-bar
      :options="{ alwaysShowTracks: true }"
      ref="scrollView"
      @scroll="tableScroll"
    >
      <div
        :style="`transform: translate3d(0px, ${tableScrollY}px, 0px)`"
        class="table_head"
        v-if="headings.length > 0"
        ref="tableHead"
      >
        <div
          class="table_row mozzarella"
          :style="
            !!columnsGrid
              ? 'grid-template-columns:' + columnsGrid.join(' ')
              : 'grid-auto-flow:column;'
          "
        >
          <div
            class="table_col"
            v-if="checkbox"
          >
            <select-checkboxes
              v-if="settings"
              :items="
                $store.getters.getTableInstantSettingFields
              "
              :is-choicest="headings"
              @change="changeSettings"
            />
          </div>
          <div
            class="table_col opacity-48 pointer flex align-items-center"
            v-for="(head, index) of headings"
            :key="'head' + index"
            @click="sortingClick(head.field)"
            :class="{
              'justify-content-center': centeredCols && head.field !== 'name'
            }"
          >
            {{ head.title }}
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.37829 11.835C8.16934 12.055 7.83058 12.055 7.62163 11.835L5.15665 9.96155C4.94771 9.74158 4.94771 9.38494 5.15665 9.16498C5.3656 8.94501 5.70436 8.94501 5.91331 9.16498L7.99996 10.6402L10.0866 9.16497C10.2956 8.94501 10.6343 8.94501 10.8433 9.16497C11.0522 9.38494 11.0522 9.74158 10.8433 9.96154L8.37829 11.835Z"
                :fill="currentSortDir === 'desc' && currentSort === head.field ? '#3D75E4': '#C0D6F6'"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.6217 4.16497C7.83064 3.94501 8.16939 3.94501 8.37832 4.16497L10.8433 6.03846C11.0522 6.25842 11.0522 6.61506 10.8433 6.83503C10.6344 7.05499 10.2956 7.05499 10.0867 6.83503L8.00001 5.35983L5.91332 6.83503C5.70439 7.05499 5.36564 7.05499 5.1567 6.83503C4.94777 6.61506 4.94777 6.25842 5.1567 6.03846L7.6217 4.16497Z"
                :fill="currentSortDir === 'asc' && currentSort === head.field ? '#3D75E4': '#C0D6F6'"
              />
            </svg>
          </div>
        </div>
      </div>
      <div
        :style="`max-height: ${bodyMaxHeight}px`"
        class="table_body"
        v-if="filteredList.length"
      >
        <router-link
          :to="rowLink + row.id + query"
          class="table_row"
          v-for="row of filteredList"
          :key="row.id + 'table_row'"
          :style="
            !!columnsGrid
              ? 'grid-template-columns:' + columnsGrid.join(' ')
              : 'grid-auto-flow:column;'
          "
        >
          <div
            class="table_col"
            v-if="checkbox"
            @click.stop
          >
            <r-checkbox
              :val="row.id"
              :value="selected"
              @input="selectedItem($event, row.id)"
            />
          </div>
          <div
            class="table_col flex align-items-center"
            :key="row.id + 'table_col' + colIndex"
            v-for="(col, colIndex) of row.columns"
            :class="{
              'justify-content-center': centeredCols && colIndex !== 'name'
            }"
          >
            <span
              v-if="colIndex === 'status'"
              class="bulb"
              :class="{
                'fargo' : !col,
                'matrix' : col,
              }"
            />
            <span
              v-else-if="showErrors"
              class="overflow"
              :title="!!col ? !!row.errors[colIndex] ? row.errors[colIndex] : col.title || col : null"
              :class="{
                'color-fargo': row.errors[colIndex],
                'roquefort': colIndex === 'name',
                'feta': colIndex !== 'name',

              }"
              v-html="(!!col ? col.title || col : '—') + getSubtitle(col)"
            />
            <span
              v-else
              class="overflow"
              :title="!!col ? col.title || col : null"
              :class="{
                'color-fargo': row.errors[colIndex],
                'roquefort': colIndex === 'name',
                'feta': colIndex !== 'name',

              }"
              v-html="(!!col ? col.title || col : '—') + getSubtitle(col)"
            />
          </div>
          <div
            class="table_col"
            v-if="deleteButton || copyButton || archiveButton"
          >
            <additional-menu>
              <ul
                class="card__menu"
                style="display: grid; grid-gap: 24px;"
              >
                <li
                  v-if="copyButton"
                  class="sulguni flex pointer opacity-72 align-items-center"
                  @click.stop.prevent="copyItem(row.id)"
                >
                  <r-icon
                    size="16"
                    fill="rocky"
                    class="mr-3"
                    icon="copy"
                  />
                  Дублировать
                </li>
                <li
                  v-if="row.active && archiveButton"
                  class="sulguni flex pointer opacity-72 align-items-center"
                  @click.stop.prevent="onArchive(row.id)"
                >
                  <r-icon
                    size="16"
                    fill="rocky"
                    class="mr-3"
                    icon="archive"
                  />
                  Архивировать
                </li>
                <li
                  v-if="deleteButton"
                  class="sulguni flex pointer opacity-72 align-items-center"
                  @click.stop.prevent="onDelete(row.id)"
                >
                  <r-icon
                    size="16"
                    class="mr-3"
                    icon="delete"
                    fill="fargo"
                  />
                  Удалить
                </li>
              </ul>
            </additional-menu>
          </div>
        </router-link>
      </div>
      <not-found v-else />
    </scroll-bar>
    <div
      class="object_manage_bar"
      v-if="selected.length"
    >
      <r-button-action
        title="Скачать отчёт"
        icon="file-download"
        class="color-rocky"
        @click.native="openDownloadReportModal"
      />
      <r-button-action
        title="Удалить"
        icon="file-download"
        transparent
        class="color-fargo"
        @click.native="onDelete(selected[0])"
      />
    </div>
  </div>
</template>

<script>
import ScrollBar from '@blackbp/vue-smooth-scrollbar';
import AdditionalMenu from './AdditionalMenu';
import DeleteModal from './DeleteModal';
import Api from '../api/Api';
import notFound from './NotFound';
import SelectCheckboxes from './SelectCheckboxes';
import DownloadReportModal from './DownloadReportModal';

export default {
  name: 'Table',
  components: { ScrollBar, AdditionalMenu, notFound, SelectCheckboxes },
  props: {
    headings: {
      type: [Array, Object],
      default: () => []
    },
    list: {
      type: Array,
      required: true
    },
    columnsGrid: {
      type: Array
    },
    rowLink: {
      type: String,
      default: ''
    },
    startSortedColumn: {
      type: String,
      default: ''
    },
    bodyMaxHeight: {
      type: String,
      default: 'auto'
    },
    query: {
      type: String,
      default: ''
    },
    centeredCols: {
      type: Boolean,
      default: false
    },
    settings: {
      type: Boolean,
      default: false
    },
    deleteButton: {
      type: Boolean,
      default: false
    },
    copyButton: {
      type: Boolean,
      default: false
    },
    archiveButton: {
      type: Boolean,
      default: false
    },
    deleteFunction: {
      type: Function,
      default: () => {
      }
    },
    checkbox: {
      type: Boolean,
      default: true
    },
    showErrors: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      rows: [],
      currentSort: 'title',
      currentSortDir: 'asc',
      selected: [],
      showSettings: false,
      tableScrollY: 0,
      tableScrollX: 0
    };
  },
  computed: {
    filteredList() {
      const { list } = this.$props;
      if (this.currentSort) {
        list.sort((a, b) => {
          let modifier = 1;
          if (!!a.columns[this.currentSort]?.title && !!b.columns[this.currentSort]?.title) {
            if (this.currentSortDir === 'desc') modifier = -1;
            if (a.columns[this.currentSort].title < b.columns[this.currentSort].title) return -1 * modifier;
            if (a.columns[this.currentSort].title > b.columns[this.currentSort].title) return 1 * modifier;
          } else {
            if (this.currentSortDir === 'desc') modifier = -1;
            if (a.columns[this.currentSort] < b.columns[this.currentSort]) return -1 * modifier;
            if (a.columns[this.currentSort] > b.columns[this.currentSort]) return 1 * modifier;
          }

          return 0;
        });
      }
      return list;
    }
  },
  deactivated() {
    this.selected = [];
    // window.removeEventListener('scroll', this.handleScroll);
  },
  created() {
    this.currentSort = this.startSortedColumn;
    // window.addEventListener('scroll', this.handleScroll);
  },
  mounted() {
  },
  methods: {
    handleScroll(e) {
      // const head = this.$refs.tableHead;
      // if(head) {
      //   const elTop = head?.getBoundingClientRect().top;
      //
      //   console.log(window.scrollY, elTop);
      //   head.style.transform = `translate3d(0px, ${window.scrollY - 290}px, 0px)`;
      // }
    },
    tableScroll(e) {
      this.tableScrollY = e.offset.y;
    },
    openDownloadReportModal() {
      this.$rir.modal.open(DownloadReportModal, {
        sensorId: this.selected[0]
      });
    },
    async changeSettings(payload) {
      await this.$store.dispatch('operateHeatSeasons', payload).then(() => {
        this.$store.dispatch('getAllObjects');
      });
    },
    selectedItem(e, id) {
      if (this.selected[0] === id) {
        this.selected = [];
      } else {
        this.selected = [id];
      }
    },
    sortingClick(field) {
      if (field === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
      }
      this.currentSort = field;
    },
    getSubtitle(col) {
      if (col?.subtitle === 0 || col?.subtitle === 1) {
        const arr = [
          {
            id: 0,
            value: 'Муниципальная собственность'
          },
          {
            id: 1,
            value: 'Неразграниченная собственность'
          }
        ];
        return `<div class="subtitle mozzarella opacity-48 mt-1">${arr[col?.subtitle].value}</div>`;
      }
      if (col?.subtitle) {
        return `<div class="subtitle mozzarella opacity-48 mt-1">${
          col?.subtitle
        }</div>`;
      }
      return '';
    },
    async onArchive(id) {
      this.$rir.modal.open(DeleteModal, {
        icon: false,
        title: 'Архивировать договор?',
        subtitle: 'Договор останется в базе данных и будет перенесён в «Архивные»',
        buttons: ['Не архивировать', 'Архивировать'],
        action: () => {
          this.archive(id);
        }
      });
    },
    archive(id) {
      new Api().deleteContract(id).then(() => {
        const r = this.$router.resolve({
          name: 'index'
        });
        window.location.assign(r.href);
      });
    },
    copyItem(id) {
      this.$router.push({ path: `contract/${id}`, query: { copy: true } });
    },
    async onDelete(id) {
      await this.$rir.modal.open(DeleteModal, {
        title: 'Удалить точку оповещения?',
        action: () => {
          this.delete(id);
        }
      });
    },
    async delete(id) {
      await new Api().operateObject({
        id,
        action: 'purge'
      }).then(() => {
        const r = this.$router.resolve({
          name: 'index'
        });
        window.location.assign(r.href);
      });
    },
    sortTable(col) {
      this.sorting *= -1;
      this.sortingType = col;
    }
  }
};
</script>

<style scoped lang="scss">

.object_manage_bar {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 15;
  width: 100%;
  max-width: calc(100% - 25.3%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 26px 40px 26px 32px;
  background: var(--rir-amelie);
  box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);

  &__popover {
    width: 150px;

    .popover_button {
      width: 100%;
      padding: 12px 16px;
      border-left: 4px solid transparent;
      transition: .2s;
      cursor: pointer;
      text-align: left;

      &:hover {
        opacity: 1;
        border-left-color: var(--rir-rocky);
      }
    }
  }
}

::v-deep .subtitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.table {
  width: calc(100% + 32px + 40px);
  position: relative;
  left: -32px;
  overflow: hidden;

  &_col {

    color: #04153e;

    .overflow {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.r-tooltip {
  position: relative;
}

.r-tooltip__wrapper--arrow {
  position: absolute;
  top: 100%;
  left: 12px;
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid;
  background-color: transparent !important;
}

.r-tooltip__wrapper {
  position: absolute;
  top: calc(100% + 6px);
  border-radius: 12px;
  padding: 4px 8px;
  left: 0;
  right: 0;
  z-index: 100;
  filter: drop-shadow(0 4px 16px rgba(48, 3, 14, 0.16));
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-size: 13px;
  line-height: 16px;
}

.table_row {
  display: grid !important;
  grid-gap: 24px;
  align-items: center;
  align-content: center;
  padding: 16px 40px 16px 32px;
  border-top: 1px solid #e4edfb;
  position: relative;
  width: max-content;
  min-width: 100%;

  &.hidden {
    overflow: hidden;
  }

  &__delete {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f6f9fe;
    box-shadow: 0px 2px 8px rgba(4, 21, 62, 0.12);
    height: 100%;
    width: 70px;
    transform: translateX(100%);
    transition: 0.2s ease-in-out;
    transition-delay: 0.3s;
    cursor: pointer;
    opacity: 0;

    .r-icon {
      transition: 0.2s ease-in-out;
    }

    &:hover {
      .r-icon {
        opacity: 1;
      }
    }
  }

  &:hover {
    .table_row__delete {
      opacity: 1;
      transform: translateX(0px);
    }
  }
}

.table_body {
  width: max-content;
  min-width: 100%;

  .table_row {
    &:hover {
      background: #f6f9fe;
    }

    &.error {
      &:hover {
        background: #fef8f9;

        .r-tooltip__wrapper {
          opacity: 1;
        }
      }

      .r-checkbox {
        pointer-events: none;
      }

      .r-checkbox__input:before {
        border: 2px solid #e14761;
        opacity: 0.32;
      }
    }
  }
}
.r-checkbox {
  height: 12px;
  width: 12px;
}

.table_head {
  width: max-content;
  min-width: 100%;
  will-change: transform;
  position: relative;
  z-index: 2;
  background: #f6f9fe;

  .table_row {
    border: none;
    padding-top: 0;
    padding-bottom: 32px;
  }
}

.draggable {
  cursor: grab;
}

.draggable:active {
  cursor: grabbing;
}

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.3;
  //background: red;
}

::v-deep .scrollbar-track-x {
  background: #f6f9fe;
  padding: 4px 0;
  height: 20px;
  bottom: unset;
  top: 28px;

  .scrollbar-thumb {
    background: #e4edfb;
    border-radius: 4px;
    height: 12px;
    top: 4px;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    &:before,
    &:after {
      content: '';
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='4' height='6' viewBox='0 0 4 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0.5 0C0.223858 0 0 0.223858 0 0.5V5.5C0 5.77614 0.223858 6 0.5 6C0.776142 6 1 5.77614 1 5.5V0.5C1 0.223858 0.776142 0 0.5 0Z' fill='%2381ABEE'/%3e%3cpath d='M3.5 0C3.22386 0 3 0.223858 3 0.5V5.5C3 5.77614 3.22386 6 3.5 6C3.77614 6 4 5.77614 4 5.5V0.5C4 0.223858 3.77614 0 3.5 0Z' fill='%2381ABEE'/%3e%3c/svg%3e ");
      width: 4px;
      height: 6px;
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      top: 50%;
      margin-top: -3px;
    }

    &:before {
      left: 4px;
    }

    &:after {
      right: 4px;
    }
  }
}
::v-deep .scrollbar-track-y {
  background: #f6f9fe;
  padding: 4px 0;
  width: 20px;
  //top: 50px;
  //height: calc(100% - 50px);
  .scrollbar-thumb {
    background: #e4edfb;
    border-radius: 4px;
    width: 12px;
    left: 4px;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    &:before,
    &:after {
      content: '';
      background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='4' height='6' viewBox='0 0 4 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0.5 0C0.223858 0 0 0.223858 0 0.5V5.5C0 5.77614 0.223858 6 0.5 6C0.776142 6 1 5.77614 1 5.5V0.5C1 0.223858 0.776142 0 0.5 0Z' fill='%2381ABEE'/%3e%3cpath d='M3.5 0C3.22386 0 3 0.223858 3 0.5V5.5C3 5.77614 3.22386 6 3.5 6C3.77614 6 4 5.77614 4 5.5V0.5C4 0.223858 3.77614 0 3.5 0Z' fill='%2381ABEE'/%3e%3c/svg%3e ");
      width: 4px;
      height: 6px;
      background-repeat: no-repeat;
      background-position: center center;
      position: absolute;
      left: 50%;
      margin-left: -3px;
    }

    &:before {
      top: 4px;
    }

    &:after {
      bottom: 4px;
    }
  }
}

.r-tooltip {
  &__wrapper {
    background-color: var(--rir-amelie);
    filter: drop-shadow(0px 4px 16px rgba(4, 21, 62, 0.16));
    left: -10px;
    top: calc(100% + 10px);
    max-width: 345px;
    pointer-events: none;
    opacity: 0;
    transition: 0.2s ease-in-out;

    &--arrow {
      border-bottom-color: var(--rir-amelie);
      border-top-color: var(--rir-amelie);
      z-index: 100;
      top: -6px;
    }
  }
}

.pb-100px {
  padding-bottom: 100px;
}

::v-deep .c-scroll-view {
  padding-bottom: 185px;
}

.table_row {
  &.disabled {
    .feta {
      opacity: .48;
    }
  }

  &.error {
    background: #FEF8F9;
  }
}

::v-deep .r-tooltip__wrapper {
  bottom: calc(100% + 6px);
  top: unset !important;
  max-width: 260px;
  text-align: left;
}

.rows_space-between {
  ::v-deep .table_row {
    justify-content: space-between;
  }
}

.bulb {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin: auto;

  &.matrix {
    background: #57A003;
    box-shadow: 0px 2px 8px rgba(87, 160, 3, 0.64);
  }

  &.fargo {
    background: #E14761;
    box-shadow: 0px 2px 8px rgba(238, 88, 126, 0.64);
  }
}

.text-center {
  text-align: center;
}

[data-tooltip] {
  cursor: help;
  overflow: visible !important;
  position: relative;

  &:before {
    content: '';
    width: 12px;
    height: 6px;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='12' height='6' viewBox='0 0 12 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M6 6L12 0L0 0L6 6Z' fill='white'/%3e%3c/svg%3e ");
    background-repeat: no-repeat;
    position: absolute;
    left: 50%;
    margin-left: -6px;
    bottom: calc(100% + 2px);
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
  }

  &::after {
    z-index: 1;
    content: attr(data-tooltip);
     opacity: 0;
     visibility: hidden;
     pointer-events: none;
    position: absolute;
    left: -14px;
    bottom: calc(100% + 8px);
    transition: all 0.2s ease-in-out;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 4px 8px;
    filter: drop-shadow(0px 4px 16px rgba(4, 21, 62, 0.16));
    max-width: 260px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover::before,
  &:hover::after {
    opacity: 1;
    visibility: visible;
  }
}
</style>
<style>
.r-popover__content {
  box-shadow: 0px 9px 28px rgba(17, 48, 121, 0.18);
  border-radius: 8px;
}
</style>
