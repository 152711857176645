<template>
  <r-popover
    v-model="showSelect"
  >
    <template #activator>
      <div
        class="r-popover__activator"
        @click="showSelect = !showSelect"
      >
        <r-input
          readonly
          :max-width="maxWidth"
          :placeholder="placeholder"
          :label="label"
          :value="model"
          class="r-select"
          :class="{
            disabled: readonly
          }"
        />
      </div>
    </template>
    <template #content>
      <r-list-item
        @click.native="!item.disabled && selectItem(item)"
        class="overflow-visible relative"
        v-for="item in itemsList"
        :class="{
          selected: item[idValue] === modelId,
          disabled: item.disabled
        }"
        :key="item.forUuId"
        :title="item[textValue]"
      >
        <template v-if="!isSlotItem">
          {{  }}
        </template>
        <r-date-picker
          v-if="item.id === 'FROM' && showPeriodDate"
          class="absolute date_selected"
          period
          inline
          v-model="periodDate"
          @input="inputDate"
        />
      </r-list-item>
    </template>
  </r-popover>
</template>

<script>
import ClickOutside from 'vue-click-outside';

export default {
  name: 'SelectWithDate',
  directives: {
    ClickOutside
  },
  props: {
    value: {
      validator: prop => ['string', 'object', 'number'].includes(typeof prop) || prop === null,
      default: () => ({})
    },
    returnObject: {
      type: Boolean
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    maxWidth: {
      type: String,
      default: '100%'
    },
    items: {
      type: Array,
      default: () => []
    },
    textValue: {
      type: String,
      default: 'value'
    },
    idValue: {
      type: String,
      default: 'id'
    },
    noDataItems: {
      type: String,
      default: 'Список пустой'
    },
    selectAll: {
      type: Boolean
    },
    readonly: {
      type: Boolean
    },
    selectFirstItem: {
      type: Boolean
    }
  },
  data: () => ({
    showSelect: false,
    item: null,
    showPeriodDate: false,
    periodDate: [new Date().toLocaleDateString('en-CA'), new Date().toLocaleDateString('en-CA')]
  }),
  computed: {
    model() {
      return this.item ? this.item[this.textValue] : null;
    },
    modelId() {
      return this.item ? this.item[this.idValue] : null;
    },
    itemsList() {
      const items = this.items.map(el => ({
        ...el
      }));
      if (this.selectAll) {
        items.unshift({
          [this.idValue]: null,
          [this.textValue]: 'Все'
        });
      }
      return items;
    },
    isSlotItem() {
      return !!this.$slots.item || !!this.$scopedSlots.item;
    }
  },
  watch: {
    value(val) {
      this.item = this.itemsList.find(
        el => el[this.idValue] === (this.returnObject ? val[this.idValue] : val)
      );
    }
  },
  mounted() {
    if (this.selectFirstItem) {
      this.item = this.items[0];
    }
    this.item = this.itemsList.find(
      el => el[this.idValue]
          === (this.returnObject ? this.value[this.idValue] : this.value)
    ) || null;
  },
  methods: {
    changeViewList() {
      this.showSelect = !this.readonly && !this.showSelect;
    },
    selectItem(item) {
      this.item = { ...item };

      delete item.forUuId;
      const val = this.returnObject ? item : item[this.idValue];
      this.$emit('change', item);
      this.$emit('input', val);
      if (item.id === 'FROM') {
        this.showPeriodDate = true;
      } else {
        this.showSelect = false;
        this.showPeriodDate = false;
      }
    },
    selectDate() {
      console.log('ssssssss');
      this.showPeriodDate = true;
    },
    hideSelect() {
      this.showSelect = false;
      // this.showPeriodDate = false;
    },
    inputDate(date) {
      this.$emit('changeDate', date);
    }
  }
};

</script>

<style scoped lang="scss">
.overflow-visible{
  overflow: visible;
}
.date_selected{
  right: calc(100% + 20px);
  top: 0;
  background-color: #fff;
  display: inline-flex;
  position: absolute;
  box-shadow: 0 8px 24px rgb(19 57 134 / 16%);
  z-index: 10000;
  min-width: 288px;
  border-radius: 16px;
  padding: 24px;
  height: 244px;
  overflow: hidden;
}
.r-list>.r-list-item.selected {
  pointer-events: none;
  color: #04153e;
  .date_selected{
    pointer-events: all;
  }
}
.r-list>.r-list-item.selected:after {
  content: "";
  position: absolute;
  background-color: #3d75e4;
  height: 100%;
  width: 4px;
  top: 0;
  left: 0;
}
.r-popover{
  display: block;
}
</style>
