<template>
  <div
    ref="content"
    :class="{ button_fly: flyButton }"
  >
    <loader v-show="isLoading"/>
    <div v-show="!isLoading">
      <admin-header
        :title="
          !isEditPage ? 'Новый объект' : formData.name || 'Объект'
        "
        :to="{ name: 'admin', query: {
          tab: $route.query.fromTab ? $route.query.fromTab : ''
        } }"
      >
        <sup
          class="text-c13 color-fargo"
          v-if="!isChangeComp && isEditPage"
        >Не сохранено</sup>
      </admin-header>
      <p
        class="feta opacity-48 mt-2"
        v-if="activeTabId.id === 'monitoring' && isEditPage"
      >
        Последние показания {{ formData.t_counter_data.created }}
      </p>
      <template v-if="activeTabId.id === 'monitoring' && isEditPage">
        <p
          class="feta color-matrix flex align-items-center mt-3"
          v-if="Object.keys(errors).length < 1"
        >
          <r-icon
            size="16"
            class="mr-2"
            icon="selected"
            fill="matrix"
          />
          Нет ошибок прибора учёта
        </p>
        <button-open-list
          v-else
          :list="errors"
          fill="fargo"
          class="mt-3"
          :title="getDeclOfNum(errors)"
          icon="warning"
        />
      </template>
      <r-tabs
        class="mt-7 mb-6"
        v-if="isEditPage && !isCopy"
        :items="tabs"
        v-model="activeTabId"
      />

      <template v-if="activeTabId.id === 'settings'">
        <div class="form mt-8 flex flex-1">
          <div class="flex flex-1 flex-direction-column">
            <r-input
              :class="{ field_error: $v.formData.name.$error }"
              label="Наименование"
              v-model="formData.name"
              @blur="isChange('name')"
              :error="$v.formData.name.$error && !$v.formData.name.required"
              error-message="Поле обязательно для заполнения"
            />
            <r-textarea
              class="mt-6"
              label="Описание"
              v-model="formData.description"
              @blur="isChange('description')"
              :rows="6"
              error-message="Поле обязательно для заполнения"
            />
            <r-input
              class="mt-10"
              label="ID счётчика LORA"
              v-model="formData.devId"
              @blur="isChange('devId')"
            />
            <r-input
              class="mt-6"
              label="UID объекта на запись целевой t°"
              v-model="formData.objectId"
              @blur="isChange('objectId')"
            />
            <r-input
              class="mt-6"
              label="UID объекта на чтение t° и Q"
              v-model="formData.device_uid"
              @blur="isChange('device_uid')"
            />
            <r-input
              class="mt-6"
              label="UID объекта на чтение внешней t°"
              v-model="formData.device_uidT"
              @blur="isChange('device_uidT')"
            />
            <r-input
              class="mt-6"
              label="UID объекта на чтение воды"
              v-model="formData.device_uidW"
              @blur="isChange('device_uidW')"
            />
            <div class="flex align-items-center mt-10">
              <r-input
                class="mr-6 flex-1"
                label="Номер договора"
                v-model="formData.contractNum"
                @blur="isChange('contractNum')"
                error-message="Поле обязательно для заполнения"
              />
              <r-date-picker
                class="flex-1"
                click-close
                label="Дата поверки"
                v-model="formData.calibrationDate"
                @input="isChange('calibrationDate')"
              />
            </div>
            <r-input
              class="mt-6"
              label="Имейл для уведомлений"
              v-model="formData.calibrationEmail"
              @blur="isChange('calibrationEmail')"
            />
            <!--<div class="field_wrapper mt-10">
              <r-textarea
                :class="{ field_error: !!jsonError }"
                label="Конфигурации информации счетчика отопления"
                v-model="formData.info"
                @blur="checkValidJson"
                @input="jsonError = null"
                :rows="6"
                :max-rows="50"
              />
              <div
                class="error mozzarella color-fargo bottom"
                v-if="!!jsonError"
              >
                {{ jsonError }}
              </div>
            </div>
            <p class="feta mt-4">
              Введите любые значения в формате JSON
            </p>-->
          </div>
          <div class="ml-6 flex flex-direction-column flex-1 overflow-hidden">
            <rir-map
              @click="onMapClick"
              collapse-btn
              style="height: 415px"
              class="mb-6"
              :center="formData.lat && formData.lng ? [formData.lat, formData.lng] : $cityCenter"
            >
              <ymap-marker
                :coords="formData.lat && formData.lng ? [formData.lat, formData.lng] : $cityCenter"
                marker-id="marker"
                :options="{
                  draggable: this.$store.getters.userIsRoot,
                }"
                :icon="$markerIcon('mapPin')"
                @dragend="onMarkerDragEnd"
              />
            </rir-map>

            <r-input
              class=" mb-6"
              label="Адрес"
              v-model="$v.formData.address.$model"
              :class="{ field_error: $v.formData.address.$error }"
              @blur="onSearchAddress(formData.address)"
              @onPressEnter="onSearchAddress(formData.address)"
              after-icon="search"
              :error="$v.formData.address.$error && !$v.formData.address.required"
              error-message="Поле обязательно для заполнения"
            />
          </div>
        </div>
        <p
          class="burrata mt-6 inline-flex align-items-center pointer"
          @click="showSettings = !showSettings"
        >
          Конфигурация информации счетчика отопления
          <r-icon
            size="16"
            class="ml-2"
            :class="{'arr-down-active': showSettings }"
            icon="arrow-down"
            fill="titanic"
          />
        </p>
        <transition name="fade">
          <div v-if="showSettings">
            <div class="flex align-items-center mt-6">
              <r-input
                class="flex-1 mr-6"
                label="Тепловычислитель"
                v-model="formData.info.teplovychislitel"
              />
              <r-input
                class="flex-1 mr-6"
                label="М. сет.воды, т.сут"
                v-model="formData.info.msetvody"
              />
              <r-input
                class="flex-1 "
                label="Тип расходомера"
                v-model="formData.info.tiprasxodomera"
              />
            </div>
            <div class="flex align-items-center mt-6">
              <r-input
                class="flex-1 mr-6"
                label="Мгвс, т.сут"
                v-model="formData.info.mgvs"
              />
              <r-input
                class="flex-1 mr-6"
                label="Серийный номер"
                v-model="formData.info.seriinynomer"
              />
              <r-input
                class="flex-1"
                label="txв, °C"
                v-model="formData.info.txv"
              />
            </div>
            <div class="flex align-items-center mt-6">
              <r-input
                class="flex-1 mr-6"
                label="БД"
                v-model="formData.info.bd"
              />
              <r-input
                class="flex-1 mr-6"
                label="txд, °C"
                v-model="formData.info.txd"
              />
              <r-input
                class="flex-1"
                label="ТВ1"
                v-model="formData.info.tv1"
              />
            </div>
            <div class="flex align-items-center mt-6">
              <r-input
                class="flex-1 mr-6"
                label="КСН"
                v-model="formData.info.ksn"
              />
              <r-input
                class="flex-1 mr-6"
                label="СИ"
                v-model="formData.info.si"
              />
              <r-input
                class="flex-1"
                label="КТ3"
                v-model="formData.info.kt3"
              />
            </div>
            <div class="flex align-items-center mt-6">
              <r-input
                class="flex-1"
                label="ФРТ"
                v-model="formData.info.frt"
              />
            </div>
            <div class="flex align-items-center mt-6">
              <r-input
                class="flex-1 mr-6"
                label="G1 max, м3/ч"
                v-model="formData.info.g1max"
              />
              <r-input
                class="flex-1 mr-6"
                label="G2 max, м3/ч"
                v-model="formData.info.g2max"
              />
              <r-input
                class="flex-1"
                label="G3 max, м3/ч"
                v-model="formData.info.g3max"
              />
            </div>
            <div class="flex align-items-center mt-6">
              <r-input
                class="flex-1 mr-6"
                label="G1 min, м3/ч"
                v-model="formData.info.g1min"
              />
              <r-input
                class="flex-1 mr-6"
                label="G2 min, м3/ч"
                v-model="formData.info.g2min"
              />
              <r-input
                class="flex-1 "
                label="G3 min, м3/ч"
                v-model="formData.info.g3min"
              />
            </div>
            <div class="flex align-items-center mt-6">
              <r-input
                class="flex-1  mr-6"
                label="Поверка расходомер 1 ГГГГ-ММ-ДД"
                v-model="formData.info.poverkaRasxodomer1"
                v-mask="'####-##-##'"
              />
              <r-input
                class="flex-1 mr-6"
                label="Поверка расходомер 2 ГГГГ-ММ-ДД"
                v-model="formData.info.poverkaRasxodomer2"
                v-mask="'####-##-##'"
              />
              <r-input
                class="flex-1"
                label="Поверка расходомер 3 ГГГГ-ММ-ДД"
                v-model="formData.info.poverkaRasxodomer3"
                v-mask="'####-##-##'"
              />
            </div>
            <div class="flex align-items-center mt-6">
              <r-input
                class="flex-1 mr-6"
                label="Поверка термометр сопротивления 1 ГГГГ-ММ-ДД"
                v-model="formData.info.poverkaTermSopr1"
                v-mask="'####-##-##'"
              />
              <r-input
                class="flex-1  mr-6"
                label="Поверка термометр сопротивления 2 ГГГГ-ММ-ДД"
                v-model="formData.info.poverkaTermSopr2"
                v-mask="'####-##-##'"
              />
              <r-input
                class="flex-1"
                label="Поверка термометр сопротивления 3 ГГГГ-ММ-ДД"
                v-model="formData.info.poverkaTermSopr3"
                v-mask="'####-##-##'"
              />
            </div>
            <div class="flex align-items-center mt-6">
              <r-input
                class="flex-1 mr-6"
                label="Поверка преобразователь давления 1 ГГГГ-ММ-ДД"
                v-model="formData.info.poverkaPreobrDavleniya1"
                v-mask="'####-##-##'"
              />
              <r-input
                class="flex-1 mr-6"
                label="Поверка преобразователь давления 2 ГГГГ-ММ-ДД"
                v-model="formData.info.poverkaPreobrDavleniya2"
                v-mask="'####-##-##'"
              />
              <r-input
                class="flex-1"
                label="Поверка преобразователь давления 3 ГГГГ-ММ-ДД"
                v-model="formData.info.poverkaPreobrDavleniya3"
                v-mask="'####-##-##'"
              />
            </div>
            <!-- <div class="flex align-items-center mt-6">
              <r-input
                class="flex-1 mr-6"
                label="teplovychislitelПояснение"
                v-model="formData.info.teplovychislitelПояснение"
              />
              <r-input
                class="flex-1 mr-6"
                label="tiprasxodomeraПояснение"
                v-model="formData.info.tiprasxodomeraПояснение"
              />
              <r-input
                class="flex-1 "
                label="msetvodyПояснение"
                v-model="formData.info.msetvodyПояснение"
              />
            </div>
            <div class="flex align-items-center mt-6">
              <r-input
                class="flex-1 mr-6"
                label="mgvsПояснение"
                v-model="formData.info.mgvsПояснение"
              />
              <r-input
                class="flex-1 mr-6"
                label="txvПояснение"
                v-model="formData.info.txvПояснение"
              />
              <r-input
                class="flex-1"
                label="txdПояснение"
                v-model="formData.info.txdПояснение"
              />
            </div>
            <div class="flex align-items-center mt-6">
              <r-input
                class="flex-1 mr-6"
                label="seriinynomerПояснение"
                v-model="formData.info.seriinynomerПояснение"
              />
              <r-input
                class="flex-1 mr-6"
                label="bdПояснение"
                v-model="formData.info.bdПояснение"
              />
              <r-input
                class="flex-1 "
                label="tv1Пояснение"
                v-model="formData.info.tv1Пояснение"
              />
            </div>
            <div class="flex align-items-center mt-6">
              <r-input
                class="flex-1 mr-6"
                label="siПояснение"
                v-model="formData.info.siПояснение"
              />
              <r-input
                class="flex-1 mr-6"
                label="kt3Пояснение"
                v-model="formData.info.kt3Пояснение"
              />
              <r-input
                class="flex-1"
                label="frtПояснение"
                v-model="formData.info.frtПояснение"
              />
            </div>
            <div class="flex align-items-center mt-6">
              <r-input
                class="flex-1 mr-6"
                label="ksnПояснение"
                v-model="formData.info.ksnПояснение"
              />
              <r-input
                class="flex-1  mr-6"
                label="g1maxПояснение"
                v-model="formData.info.g1maxПояснение"
              />
              <r-input
                class="flex-1"
                label="g2maxПояснение"
                v-model="formData.info.g2maxПояснение"
              />
            </div>
            <div class="flex align-items-center mt-6">
              <r-input
                class="flex-1 mr-6"
                label="g3maxПояснение"
                v-model="formData.info.g3maxПояснение"
              />
              <r-input
                class="flex-1 mr-6"
                label="g1minПояснение"
                v-model="formData.info.g1minПояснение"
              />
              <r-input
                class="flex-1"
                label="g2minПояснение"
                v-model="formData.info.g2minПояснение"
              />
            </div>
            <div class="flex align-items-center mt-6">
              <r-input
                class="flex-1"
                label="g3minПояснение"
                v-model="formData.info.g3minПояснение"
              />
            </div>-->
          </div>
        </transition>
        <div>
          <r-button-action
            title="Удалить"
            icon="delete"
            color="fargo"
            v-if="isEditPage && $store.getters.userIsRoot"
            class="color-fargo sulguni mt-10"
            @click="onDelete"
          />
        </div>
        <div class="button_container">
          <r-button
            @click="submit"
            :disabled="isChangeComp"
            width="wide"
            :title="isEditPage ? 'Сохранить' : 'Добавить'"
          />
        </div>
      </template>

      <object-tab-monitoring
        v-if="activeTabId.id === 'monitoring' && (isEditPage && !isCopy)"
        :data="{...formData.t_counter_data, ...formData}"
      />
      <object-tab-hours v-if="activeTabId.id === 'hours' && (isEditPage && !isCopy)"/>
    </div>
  </div>
</template>

<script>
import {required} from 'vuelidate/lib/validators';
import _ from 'lodash';
import AdminHeader from '../components/AdminHeader';
import DeleteModal from '../components/DeleteModal';
import Api from '../api/Api';
import RirMap from '../components/RirMap';
import ObjectTabMonitoring from '../components/ObjectTabMonitoring';
import ObjectTabHours from '../components/ObjectTabHours';
import {declOfNum, getErrors} from '../helpers/utils';
import ButtonOpenList from '../components/ButtonOpenList';
import Loader from '../components/Loader';

export default {
  name: 'Object',
  components: {Loader, AdminHeader, RirMap, ObjectTabMonitoring, ButtonOpenList, ObjectTabHours},
  data() {
    return {
      showSettings: false,
      canChange: false,
      isSave: false,
      flyButton: null,
      isLoading: false,
      isMapReady: false,
      emptyFormData: {},
      initialData: {},
      loadedImg: null,
      activeTabId: {
        id: 'monitoring',
        title: 'Мониторинг'
      },
      tabs: [
        {
          id: 'monitoring',
          title: 'Мониторинг'
        },
        {
          id: 'hours',
          title: 'Почасовой расход'
        },
        {
          id: 'settings',
          title: 'Настройки'
        }
      ],
      formData: {
        name: null,
        description: null,
        devId: null,
        objectId: null,
        device_uid: null,
        device_uidT: null,
        device_uidW: null,
        calibrationDate: null,
        calibrationEmail: null,
        contractNum: null,
        info: {
          teplovychislitel: null,
          msetvody: null,
          tiprasxodomera: null,
          mgvs: null,
          seriinynomer: null,
          txv: null,
          bd: null,
          txd: null,
          tv1: null,
          ksn: null,
          si: null,
          kt3: null,
          frt: null,
          g1max: null,
          g2max: null,
          g3max: null,
          g1min: null,
          g2min: null,
          g3min: null,
          poverkaRasxodomer1: null,
          poverkaRasxodomer2: null,
          poverkaRasxodomer3: null,
          poverkaTermSopr1: null,
          poverkaTermSopr2: null,
          poverkaTermSopr3: null,
          poverkaPreobrDavleniya1: null,
          poverkaPreobrDavleniya2: null,
          poverkaPreobrDavleniya3: null
          /* teplovychislitelПояснение: null,
          tiprasxodomeraПояснение: null,
          msetvodyПояснение: null,
          mgvsПояснение: null,
          txvПояснение: null,
          txdПояснение: null,
          seriinynomerПояснение: null,
          bdПояснение: null,
          tv1Пояснение: null,
          siПояснение: null,
          kt3Пояснение: null,
          frtПояснение: null,
          ksnПояснение: null,
          g1maxПояснение: null,
          g2maxПояснение: null,
          g3maxПояснение: null,
          g1minПояснение: null,
          g2minПояснение: null,
          g3minПояснение: null */
        },
        lat: null,
        lng: null,
        address: null,
        t_counter_data: {
          id: null,
          created: null,
          device_uid: null,
          type_h_d: null,
          q0_data: null,
          q_noreg: null,
          t1_data: null,
          t2_data: null,
          t3_data: null,
          dt_data: null,
          p1_data: null,
          p2_data: null,
          p3_data: null,
          v1_data: null,
          v2_data: null,
          v3_data: null,
          m1_data: null,
          m2_data: null,
          m3_data: null,
          dm_data: null,
          qg_data: null,
          nwt_data: null,
          wct_data: null,
          fin_effect: null,
          utime: null,
          datef: null
        }
      },
      jsonError: null,
      errors: {}
    };
  },
  validations: {
    formData: {
      name: {
        required
      },
      address: {
        required
      }
    }
  },
  computed: {
    isChangeComp() {
      return _.isEqual(this.formData, this.initialData);
    },
    markerCoords() {
      const {lat} = this;
      const {lng} = this;
      if (lat && lng) {
        return [lng, lat];
      }

      return null;
    },
    isEditPage() {
      return !!this.$route.params.id && !this.isCopy;
    },
    isCopy() {
      return !!this.$route.query.copy;
    }
  },
  async created() {
    this.emptyFormData = this.formData;
    if (this.isEditPage) {
      this.activeTabId = this.tabs.find(item => item.id === this.$route.query.object) || {
        id: 'settings',
        title: 'Настройки'
      };
    }
  },
  mounted() {
    this.flyButton = this.$refs.content.offsetHeight > window.innerHeight;
    if (!this.isEditPage) {
      this.isSave = false;

    }
  },
  activated() {
    this.resetData();
    (this.isEditPage || this.isCopy) && this.setData();

    if (!this.isEditPage) {
      this.isSave = false;
    }

    this.isMapReady = true;
  },
  deactivated() {
    // this.intervalID && clearInterval(this.intervalID);
    this.isMapReady = false;
  },
  methods: {
    getDeclOfNum(num) {
      const count = Object.keys(num)?.length;
      return `${count} ${declOfNum(count, ['ошибка', 'ошибки', 'ошибок'])}`;
    },
    checkValidJson() {
      try {
        this.jsonError = null;
        JSON.parse(this.formData.info);
        this.isChange('info');
      } catch (err) {
        this.jsonError = err;
      }
    },
    async onSearchAddress(address) {
      if (!address) return;
      const api = new Api();

      const data = {};
      const isFindAddress = Array.isArray(address);

      if (isFindAddress) {
        data.lat = address[0];
        data.lng = address[1];
      } else {
        data.address = this.formData.address;
      }

      const res = await api.getAddress(JSON.stringify(data));
      this.formData.lat = res.lat;
      this.formData.lng = res.lng;
      if (isFindAddress && res?.address) {
        if (res?.addressFull?.street && res?.addressFull?.house) {
          this.formData.address = `${res.addressFull.street} ${res.addressFull.house}`;
        } else {
          this.formData.address = res.address;
        }
      }
      this.isChange('address');
    },
    async onDelete() {
      await this.$rir.modal.open(DeleteModal, {
        title: 'Удалить точку оповещения?',
        action: () => {
          this.delete(this.$route.params.id);
        }
      });
    },
    async delete(id) {
      await new Api().operateObject({
        id,
        action: 'purge'
      }).then(() => {
        const r = this.$router.resolve({
          name: 'index'
        });
        window.location.assign(r.href);
      });
    },
    async setData() {
      this.isLoading = true;
      await this.$store.dispatch('getObjectById', this.$route.params.id).then(async res => {
        this.formData = {
          ...this.formData,
          ...res.all[0],
          t_counter_data: {
            ...this.emptyFormData.t_counter_data,
            ...res.all[0].t_counter_data
          },
          info: res.all[0].info
        };
        this.errors = {...getErrors(this.formData), ...this.formData.calibrationExpired};
        if (this.isEditPage) {
          this.isSave = true;
        }
      }).finally(() => {
        this.isLoading = false;
        this.initialData = JSON.parse(JSON.stringify(this.formData));
      });
    },
    onMarkerDragEnd(e) {
      if (!this.$store.getters.userIsRoot) return;
      const coords = e.originalEvent.target.geometry.getCoordinates();
      if (coords?.length === 2) {
        this.lat = coords[0];
        this.lng = coords[1];
        this.onSearchAddress(coords);
      }
    },
    async onMapClick(coords) {
      if (!this.$store.getters.userIsRoot) return;
      if (coords?.length === 2) {
        this.lng = coords[0];
        this.lat = coords[1];
      }

      this.onSearchAddress(coords);
    },
    resetData() {
      this.$v.$reset();
      this.formData = JSON.parse(JSON.stringify(this.emptyFormData));
    },
    async submit() {
      this.checkValidJson();
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$nextTick(() => {
          document.querySelector('.field_error').scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        });
      } else {
        const data = {
          id: this.$route.params.id ? this.$route.params.id : -1,
          action: 'update',
          item: {
            id: this.$route.params.id ? this.$route.params.id : -1,
            ...this.formData
          }
        };
        if (this.isCopy) {
          data.id = -1;
          data.item.id = -1;
        }
        new Api()
          .operateObject(data)
          .then(() => {
            const r = this.$router.resolve({
              name: 'index'
            });
            window.location.assign(r.href);
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    isChange(field) {
      if (!this.isCopy) {
        this.isSave = this.initialData[field] === this.formData[field];
      }
    }
  }
};
</script>

<style scoped lang="scss">
.disabled {
  pointer-events: none;
  opacity: .32;
}

.width-max {
  width: max-content !important;
}

.button_container {
  margin-top: 64px;
  padding-top: 32px;
}

.button_fly {
  padding-bottom: 112px;

  .button_container {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 15;
    width: 100%;
    max-width: calc(100% - 25.3%);
    background: #ffffff;
    box-shadow: 0px 8px 32px rgba(4, 21, 62, 0.16);
    padding-left: 31px;
    padding-right: 40px;
    padding-bottom: 40px;
  }
}

.field_wrapper {
  position: relative;

  .field_error {
    &.r-input {
      background: rgba(#e14761, 0.08);
    }

    &.r-textarea {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .r-input {
      background: rgba(#e14761, 0.08);
    }

    ::v-deep .r-drag-upload-file {
      border-color: #e14761;
    }
  }

  .error {
    position: absolute;
    font-size: 12px;
    top: 1px;
    right: 16px;
    pointer-events: none;

    &.bottom {
      top: calc(100% + 1px);
      right: unset;
      left: 16px;
    }
  }
}

::v-deep .r-tooltip__wrapper {
  bottom: calc(100% + 6px);
  top: unset !important;
  max-width: 260px;
  text-align: left;
}

::v-deep .r-modal__content {
  display: block !important;
}

::v-deep textarea:focus {
  background: transparent !important;
}

.rotate-animation {
  animation: spin .8s linear 0s infinite;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}

.arr-down-active {
  transform: rotate(180deg);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

</style>
