var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"download_modal"},[_c('p',{staticClass:"camembert mb-10"},[_vm._v(" Скачать отчет ")]),_c('r-select',{staticClass:"flex-1 mb-6",attrs:{"items":_vm.$store.state.object.queryPeriodList,"label":"Выборка из базы данных"},model:{value:(_vm.queryPeriod),callback:function ($$v) {_vm.queryPeriod=$$v},expression:"queryPeriod"}}),_c('div',{staticClass:"flex align-items-center mb-6"},[_c('r-date-picker',{staticClass:"flex-1 mr-6",class:{ field_error: _vm.$v.dateFrom.$error },attrs:{"label":"C","click-close":"","error":_vm.$v.dateFrom.$error && !_vm.$v.dateFrom.required,"error-message":"Поле обязательно для заполнения"},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}}),_c('r-date-picker',{staticClass:"flex-1",attrs:{"label":"По","start-date":_vm.dateFrom,"click-close":""},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1),_c('r-select',{staticClass:"flex-1 mb-1",class:{ field_error: _vm.$v.selectedFormat.$error },attrs:{"items":[
      {
        id: 'PDF',
        title: 'PDF'
      },
      {
        id: 'XLS',
        title: 'XLS'
      }
    ],"label":"Формат отчёта","error":_vm.$v.selectedFormat.$error && !_vm.$v.selectedFormat.required,"error-message":"Поле обязательно для заполнения"},model:{value:(_vm.selectedFormat),callback:function ($$v) {_vm.selectedFormat=$$v},expression:"selectedFormat"}}),_c('r-button',{staticClass:"mt-10",attrs:{"title":'Скачать ' + _vm.selectedFormat,"width":"wide"},nativeOn:{"click":function($event){return _vm.submit.apply(null, arguments)}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }