<template>
  <div class="calendar">
    <scroll-bar
      :options="{ alwaysShowTracks: true }"
      ref="scrollView"
    >
      <div class="calendar__row">
        <div class="calendar__col mozzarella anie">
          Объект
        </div>
        <div
          class="calendar__col d-flex align-items-center flex-direction-column"
          v-for="(day, index) of days"
          :key="index"
        >
          <p
            class="ekas mb-1"
            :class="{
              'lebowski--text' : isWeekEnd(day),
              'bryndza': new Date(day).setHours(3,0,0,0).valueOf() === new Date().setHours(3,0,0,0).valueOf()
            }"
          >
            {{ new Date(day).toLocaleDateString(undefined, {day: 'numeric'}) }}
          </p>
          <p
            class="anie"
            :class="{
              'lebowski--text' : isWeekEnd(day),
              'bryndza': new Date(day).setHours(3,0,0,0).valueOf() === new Date().setHours(3,0,0,0).valueOf()
            }"
          >
            {{ new Date(day).toLocaleDateString(undefined, {weekday: 'short'}) }}
          </p>
        </div>
      </div>
      <calendar-row
        v-for="object of filteredObjects"
        :key="object.id"
        :object="object"
        :days="days"
      />
    </scroll-bar>
  </div>
</template>

<script>
import ScrollBar from '@blackbp/vue-smooth-scrollbar';
import CalendarRow from './CalendarRow';

export default {
  name: 'Calendar',
  components: {
    ScrollBar,
    CalendarRow
  },
  props: {
    period: {
      type: [Array, null],
      default: () => []
    },
    selectedSort: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      days: [],
      sort: 1
    };
  },
  computed: {
    filteredObjects() {
      let objects = JSON.parse(JSON.stringify(this.$store.state.object.allObjects));
      if (this.sort === 2) {
        objects = objects.sort((a, b) => new Date(b.calibrationDate) - new Date(a.calibrationDate));
      }
      return objects;
    }
  },
  watch: {
    period(newVal) {
      this.getDates(
        new Date(newVal[0]) || this.period[0],
        new Date(newVal[1]) || this.period[1]
      );
    },
    selectedSort(newVal) {
      this.sort = newVal;
    }
  },
  mounted() {
    this.getDates(new Date(this.period[0]), new Date(this.period[1]));
    this.sort = this.$props.selectedSort;
  },
  methods: {
    isCalibrationDay(calibrationDate, day) {
      return new Date(calibrationDate).getTime() === new Date(day).getTime();
    },
    isWeekEnd(date) {
      const day = new Date(date).toLocaleDateString(undefined, { weekday: 'short' });
      return day === 'сб' || day === 'вс';
    },
    getDates(startDate, stopDate) {
      Date.prototype.addDays = function (days) {
        const dat = new Date(this.valueOf());
        dat.setDate(dat.getDate() + days);
        return dat;
      };
      const dateArray = [];
      let currentDate = startDate;
      while (currentDate <= stopDate) {
        dateArray.push(currentDate);
        currentDate = currentDate.addDays(1);
      }
      this.days = dateArray;
    }
  }
};
</script>

<style scoped lang="scss">
.calendar {
  padding-bottom: 30px;

  &__row {
    display: grid;
    grid-template-columns: 245px repeat( auto-fit, 32px );
    align-items: center;
    grid-auto-flow: column;

    .calendar__col {
      &:not(:first-child) {
        width: 32px;
      }

      * {
        flex-shrink: 0;
      }
    }
  }

  &__day {
    width: 16px;
    height: 16px;
  }
}

::v-deep .scrollbar-track-x {
  height: 4px;
  bottom: unset;
  top: 0;
  background: transparent;

  .scrollbar-thumb {
    background: #e4edfb;
    border-radius: 4px;
    height: 4px;
    top: 0;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }
}

::v-deep .c-scroll-view {
  padding-top: 18px;
}
</style>
