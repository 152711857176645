<template>
  <div class="objects">
    <div class="flex mb-6 align-items-center">
      <r-date-picker
        :click-close="true"
        :period="true"
        label="Период"
        :start-date="null"
        :end-date="null"
        v-model="period"
        class="flex-1 mr-6"
      />
      <r-select
        class="flex-1"
        :items="[
          {
            id: 1,
            title: 'По умолчанию'
          },
          {
            id: 2,
            title: 'По дате'
          },
        ]"
        label="Сортировка"
        v-model="selectedSort"
      />
    </div>
    <loader v-if="isLoading" />
    <template v-else>
      <calendar
        :period="period"
        :selected-sort="selectedSort"
      />
    </template>
  </div>
</template>

<script>
import Calendar from './Calendar';
import Loader from './Loader';
import { formattedDate } from '../helpers/utils';

export default {
  name: 'TabCalendar',
  components: {
    Calendar,
    Loader
  },
  data() {
    return {
      isLoading: false,
      period: null,
      selectedSort: 1
    };
  },
  created() {
    const format = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    };
    const date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.period = [
      new Date(firstDay).toLocaleString('en-CA', format),
      new Date(lastDay).toLocaleString('en-CA', format)
    ];
  }
};
</script>

<style scoped>

</style>
