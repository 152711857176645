import Vue from 'vue';
import Vuex from 'vuex';
import Api from '@/api/Api';
import object from './modules/object';

const api = new Api();
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    userAccesses: {},
    userId: null,
    cityId: null,
    errors: []
  },
  mutations: {
    setErrors(state, error) {
      state.errors.push(error)
    },
    setUser(state, user) {
      state.user = user;
    },
    setUserAccesses(state, user) {
      state.userAccesses = user;
      state.user = user.user;
    },
    setUserId(state, user) {
      state.userId = user.userId;
    },
    setCityId(state, cityId) {
      state.cityId = cityId;
    }
  },
  getters: {
    userAccesses(state) {
      return state.userAccesses;
    },
    userIsRoot(state) {
      return state.userAccesses.access.role === 'ADMIN';
    }
  },
  actions: {

    async getUserInfo(ctx) {
      const res = await api.getUserInfo();
      ctx.commit('setUserId', res);
      // console.log('userInfo', res, ctx.state.user);
    },
    async getUserAccesses(ctx) {
      const res = await api.getUserAccesses();
      ctx.commit('setUserAccesses', res);
      // console.log('userInfo', res, ctx.state.user);
    },
    async getCityId(ctx) {
      const res = await api.getCityId();
      ctx.commit('setCityId', res.cityId);
    }

  },
  modules: {
    object
  }
});
