<template>
  <div>
    <div class="calendar__row">
      <div class="calendar__col mozzarella py-2 d-flex align-items-center">
        <r-button-action
          :icon="isShow ? 'remove' :'add'"
          :title="object.name"
          color="harakiri"
          size="briscola"
          @click="isShow = !isShow"
        />
      </div>
      <div
        class="calendar__col d-flex align-items-center flex-direction-column"
        v-for="(day, index) of days"
        :key="index"
      >
        <p
          class="calendar__day rajol"
          :class="{
            // 'lebowski--bg biqe': isWeekEnd(day) && !isCalibrationDay(object.calibrationDate, day),
            'metropolis--bg kima': !checkAllCalibrationDays(day),
            'matrix--bg biqe': checkAllCalibrationDays(day) && !(new Date(day).getTime() < new Date().getTime()),
            'fargo--bg biqe': checkAllCalibrationDays(day) && (new Date(day).getTime() < new Date().getTime())
          }"
        />
      </div>
    </div>

    <div
      v-show="isShow"
      class="calendar__row"
      v-for="(name, key) of devicesName"
      :key="key"
    >
      <router-link
        :to="{
          path: `edit/${object.id}`,
          query: {
            object: 'settings'
          }
        }"
        class="calendar__col mozzarella py-2 d-flex align-items-center"
      >
        {{ name }}
      </router-link>
      <div
        class="calendar__col d-flex align-items-center flex-direction-column"
        v-for="(day, index) of days"
        :key="index"
      >
        <p
          class="calendar__day rajol"
          :class="{
            // 'lebowski--bg biqe': isWeekEnd(day) && !isCalibrationDay(object.info[key], day),
            'metropolis--bg kima': !isCalibrationDay( key === 'calibrationDate' ? object[key] : object.info[key], day),
            'matrix--bg biqe': isCalibrationDay(
              key === 'calibrationDate' ? object[key] : object.info[key], day)
              && !(new Date(day).getTime() < new Date().getTime()),
            'fargo--bg biqe': isCalibrationDay(
              key === 'calibrationDate' ? object[key] : object.info[key], day)
              && (new Date(day).getTime() < new Date().getTime())
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CalendarRow',
  props: {
    object: {
      type: Object,
      default: () => {}
    },
    days: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isShow: false,
      devicesName: {
        calibrationDate: 'Поверка',
        poverkaRasxodomer1: 'Поверка расходомер 1',
        poverkaRasxodomer2: 'Поверка расходомер 2',
        poverkaRasxodomer3: 'Поверка расходомер 3',
        poverkaTermSopr1: 'Поверка термометр сопротивления 1',
        poverkaTermSopr2: 'Поверка термометр сопротивления 2',
        poverkaTermSopr3: 'Поверка термометр сопротивления 3',
        poverkaPreobrDavleniya1: 'Поверка преобразователь давления 1',
        poverkaPreobrDavleniya2: 'Поверка преобразователь давления 2',
        poverkaPreobrDavleniya3: 'Поверка преобразователь давления 3'
      }
    };
  },
  methods: {
    isCalibrationDay(calibrationDate, day) {
      return new Date(calibrationDate).getTime() === new Date(day).getTime();
    },
    checkAllCalibrationDays(day) {
      for (const devicesNameKey in this.devicesName) {
        let a = false;
        if (devicesNameKey === 'calibrationDate') {
          a = new Date(this.object[devicesNameKey]).getTime() === new Date(day).getTime();
        } else {
          a = new Date(this.object.info[devicesNameKey]).getTime() === new Date(day).getTime();
        }
        if (a) {
          return a;
        }
      }
      return false;
    },
    isWeekEnd(date) {
      const day = new Date(date).toLocaleDateString(undefined, { weekday: 'short' });
      return day === 'сб' || day === 'вс';
    }
  }
};
</script>

<style scoped lang="scss">
.calendar{
  &__row {
    display: grid;
    grid-template-columns: 245px repeat( auto-fit, 32px );
    align-items: center;
    grid-auto-flow: column;
    .calendar__col{
      &:not(:first-child){
        width: 32px;
      }
      *{
        flex-shrink: 0;
      }
    }
  }
  &__day{
    width: 16px;
    height: 16px;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
