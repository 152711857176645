<template>
  <div class="objects">
    <div class="flex mb-6">
      <router-link
        class="flex align-items-center sulguni color-rocky mr-6"
        to="add"
        v-if="this.$store.getters.userIsRoot"
      >
        <r-icon
          size="16"
          fill="rocky"
          class="mr-2"
          icon="add"
        />
        Добавить вручную
      </router-link>
    </div>
    <div class="flex mb-6 align-items-center">
      <r-select
        class="flex-1 mr-6"
        :items="statuses"
        label="Статус"
        v-model="selectedStatus"
      />
      <r-input
        class="flex-1"
        label="Поиск"
        v-model.trim="search"
        before-icon="search"
      />
    </div>
    <loader v-if="isLoading" />
    <template v-else>
      <div class="flex mt-2 mb-2">
        <div class="ml-auto parmigiano opacity-48">
          {{ !!searchText ? "Найдено" : "Всего" }} {{ countObjects }}
        </div>
      </div>
      <scroll-table
        :list="filteredObjects"
        :headings="headings"
        :columns-grid="tableGridColumns"
        row-link="/edit/"
        start-sorted-column="name"
        query="?fromTab=gvs"
      />
      <!-- <not-found v-if="countObjects < 1" /> -->
    </template>
  </div>
</template>

<script>

import NotFound from './NotFound';
import ScrollTable from './Table';
import { getErrors, getNumberOfDays, wordMatch } from '../helpers/utils';
import Loader from './Loader';

export default {
  name: 'TabGVS',
  components: {
    Loader,
    NotFound,
    ScrollTable
  },
  data() {
    return {
      timeoutId: null,
      statuses: [
        {
          id: 'ALL',
          title: 'Все'
        },
        {
          id: 'NO_ERR',
          title: 'Без ошибок'
        },
        {
          id: 'YES_ERR',
          title: 'С ошибками'
        }
      ],
      selectedStatus: 'ALL',
      searchText: '',
      // table
      headings: [
        {
          title: 'Наименование',
          field: 'name'
        },
        {
          title: 't3, °C ',
          field: 't3_data'
        },
        {
          title: 'ГВС, м3',
          field: 'v3_data'
        },
        {
          title: 'Qr, Гкал',
          field: 'qg_data'
        }
      ],
      tableGridColumns: [
        '16px',
        '160px',
        '100px',
        '100px',
        '100px'
      ]
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.object.isLoading;
    },
    filteredObjects() {
      let list = this.$store.getters.getObjectsForTableGVS;

      if (this.searchText) {
        list = list.filter(
          el => wordMatch(el?.columns?.name, this.searchText) <= 0.5
        );
      }
      if (this.selectedStatus === 'NO_ERR') {
        list = list.filter(el => (!Object.keys(getErrors(el)).length));
      }
      if (this.selectedStatus === 'YES_ERR') {
        list = list.filter(el => (!!Object.keys(getErrors(el)).length));
      }
      return list;
    },
    countObjects() {
      return this.filteredObjects.length;
    },
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          this.searchText = typeof val === 'string' ? val.toLowerCase() : null;
        }, 420);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.icon-close {
  cursor: pointer;
  position: absolute;
  margin-left: calc(100% - 60px);
  margin-top: -28px;
}

.icon-div {
  display: table;
  position: relative;
}

.objects {
  &__filter {
    display: flex;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 4px;

    > * {
      min-width: 0;
    }
  }

  &__map {
    height: 700px;
    border-radius: 24px;
    overflow: hidden;
  }
}

::v-deep .r-popover {
  display: flex;
  align-items: center;

  &__activator {
    width: 100%;
  }

  .r-icon {
    &.add-icon {
      margin-right: 8px;
    }

    &.data-download__arrow {
      margin-left: 8px;
    }
  }
}

.data-download {
  &__wrapper {
    padding: 12px;

    .r-button {
      display: flex;
      align-items: center;

      .r-icon {
        margin-right: 8px;
      }
    }
  }
}

::v-deep .ya-map-point {
  position: absolute;
  left: -4px;
  top: -4px;
  display: block;
  width: 8px;
  height: 8px;
  background-color: #fff;
  border: 2px solid var(--rir-rocky);
  border-radius: 50%;
}

::v-deep .ya-map-point--edge {
  cursor: pointer !important;
  position: absolute;
  left: -3px;
  top: -3px;
  display: block;
  width: 6px;
  height: 6px;
  background-color: var(--rir-rocky);
  border-radius: 50%;
  opacity: 0.8;
}

</style>
