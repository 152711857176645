var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calendar"},[_c('scroll-bar',{ref:"scrollView",attrs:{"options":{ alwaysShowTracks: true }}},[_c('div',{staticClass:"calendar__row"},[_c('div',{staticClass:"calendar__col mozzarella anie"},[_vm._v(" Объект ")]),_vm._l((_vm.days),function(day,index){return _c('div',{key:index,staticClass:"calendar__col d-flex align-items-center flex-direction-column"},[_c('p',{staticClass:"ekas mb-1",class:{
            'lebowski--text' : _vm.isWeekEnd(day),
            'bryndza': new Date(day).setHours(3,0,0,0).valueOf() === new Date().setHours(3,0,0,0).valueOf()
          }},[_vm._v(" "+_vm._s(new Date(day).toLocaleDateString(undefined, {day: 'numeric'}))+" ")]),_c('p',{staticClass:"anie",class:{
            'lebowski--text' : _vm.isWeekEnd(day),
            'bryndza': new Date(day).setHours(3,0,0,0).valueOf() === new Date().setHours(3,0,0,0).valueOf()
          }},[_vm._v(" "+_vm._s(new Date(day).toLocaleDateString(undefined, {weekday: 'short'}))+" ")])])})],2),_vm._l((_vm.filteredObjects),function(object){return _c('calendar-row',{key:object.id,attrs:{"object":object,"days":_vm.days}})})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }